import React from 'react';
import Typography from "@material-ui/core/Typography";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import PersonIcon from "@material-ui/icons/Person";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import TextField from "@material-ui/core/TextField";
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Alert from '@material-ui/lab/Alert';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from '@material-ui/core/CircularProgress';

import { salesmanService } from '_services/salesman.service';

const ProfileEditForm = (props) => {

    const initials = salesmanService.getSalesmanInitials();
    const fullName = salesmanService.getSalesmanFullaName();

    const validationSchema = Yup.object({
        firstName: Yup.string().required("Το Όνομα είναι υποχρεωτικό"),
        lastName: Yup.string().required("Το Επίθετο είναι υποχρεωτικό"),
        mobilePhone: Yup.string().matches("^69[0-9]{8}$", "Το νούμερο του κινητου δεν είναι σωστό").required("Το Κινητό είναι υποχρεωτικό"),
        email: Yup.string().email("Το email δεν είναι σωστό").required("Το Email είναι υποχρεωτικό")
      });

      const { handleSubmit, handleChange, values, errors, touched, isSubmitting, status } = useFormik({
        initialValues: {
            firstName : props.userProfile.firstName,
            lastName : props.userProfile.lastName,
            email: props.userProfile.email,
            mobilePhone: props.userProfile.mobilePhone
        },
        validationSchema,
        onSubmit(values, { setStatus, setSubmitting }) {
          setStatus();
          salesmanService.editProfile(values.firstName, values.lastName, values.email, values.mobilePhone).then(
            (status) => {
                props.submitted(status);
            },
            (error) => {
              var msg = error;
              setSubmitting(false);
              setStatus(msg);
            }
          );
        },
      });

    return (
        <Grid container item alignItems="center" alignContent="center" className="dashboard" xs={12}>
            <Grid item className="card-container">
                <Avatar alt={initials} className="large-avatar">
                {initials}
                </Avatar>
                <Typography variant="h3" className="fullname">
                    {fullName}
                </Typography>
                <Grid  >
                    <Typography variant="h2">Προσωπικά Στοιχεία:</Typography>
                </Grid>

                <Card>
                    <CardContent>
                        <form onSubmit={handleSubmit}>
                            <Box display="flex" alignContent="stretch" alignItems="flex-end" pb={1}  pt={1}>
                                <Box  pr={1}>
                                    <PersonIcon />
                                </Box>
                                <Box flexGrow={1}>
                                    <TextField fullWidth label="Όνομα" id="firstName" name="firstName" type="text" onChange={handleChange} value={values.firstName}
                                        helperText={errors.firstName && touched.firstName ? errors.firstName : ""}
                                        error={errors.firstName && touched.firstName ? "error" : undefined} />
                                </Box>
                            </Box>
                            <Box display="flex" alignContent="stretch" alignItems="flex-end" pb={1}  pt={1}>
                                <Box  pr={1}>
                                    <PersonIcon />
                                </Box>
                                <Box flexGrow={1}>
                                    <TextField fullWidth label="Επίθετο" id="lastName" name="lastName" type="text" onChange={handleChange} value={values.lastName}
                                        helperText={errors.lastName && touched.lastName ? errors.lastName : ""}
                                        error={errors.lastName && touched.lastName ? "error" : undefined} />
                                </Box>
                            </Box>
                            <Box display="flex" alignContent="space-between" alignItems="flex-end" pb={1} pt={1} >
                                <Box pr={1}>
                                    <EmailIcon />
                                </Box>
                                <Box flexGrow={1}>
                                    <TextField fullWidth label="Email" id="email" name="email" type="text" onChange={handleChange} value={values.email}
                                        helperText={errors.email && touched.email ? errors.email : ""}
                                        error={errors.email && touched.email ? "error" : undefined} />
                                </Box>
                            </Box>
                            <Box display="flex" alignContent="space-between" alignItems="flex-end" pb={1} pt={1} >
                            <Box pr={1}>
                                <PhoneIphoneIcon />
                            </Box>
                            <Box flexGrow={1}>
                                <TextField fullWidth label="Κινητό" id="mobilePhone" name="mobilePhone" type="text" onChange={handleChange} value={values.mobilePhone}
                                    helperText={errors.mobilePhone && touched.mobilePhone ? errors.mobilePhone : ""}
                                    error={errors.mobilePhone && touched.mobilePhone ? "error" : undefined} />
                                </Box>
                            </Box>
                            <Button type="submit" fullWidth variant="contained" color="primary" disabled={isSubmitting}>
                                ΕΝΗΜΕΡΩΣΗ
                            </Button>
                            {status && (
                                <Alert severity="error">
                                    {status}
                                </Alert>
                            )}
                        </form>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}

function PostSubmitDialog(props) {
    const [open, setOpen] = React.useState(props.open);
    const history = useHistory();

    React.useEffect(() => {
      setOpen(props.open);
    }, [props.open]);
  
    const handleClose = () => {
        history.push("/order");
    };
  
    return (
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
        <DialogTitle id="alert-dialog-title">
          {"Ενημέρωση Προσωπικών Στοιχείων"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.dialog ? props.dialog : "Η ενημέρωση των προσωπικών στοιχείων ολοκληρώθηκε με επιτυχία."}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>OK</Button>
        </DialogActions>
      </Dialog>
    );
  }


export class ProfileEdit extends React.Component {

    constructor(props) {
      super(props);
      let userProfile = {
        firstName : "",
        lastName : "",
        email : "",
        MobilePhone : ""
      };
      this.state = { userProfile : userProfile, showSubmitDialog : false, loading : true };
      this.submitted = this.submitted.bind(this);
      this.redirect = this.redirect.bind(this);
    }

    componentDidMount() {
        salesmanService.getUserProfileData()
        .then((userProfile) => {
            this.setState({ userProfile : userProfile, loading : false });
        })
    }

    submitted()
    {
        this.setState({ showSubmitDialog : true });
    }

    redirect()
    {

    }


    render() {

        let contents = this.state.loading ? (
            <div className="progress-container"><CircularProgress /></div>
          ) : (
            <ProfileEditForm userProfile={this.state.userProfile} submitted={this.submitted} />
          );

        return (
            <Box className="full-width">
                {contents}
                <PostSubmitDialog open={this.state.showSubmitDialog} />
            </Box>
        );
    }
}
  
