import React, { useState } from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';

export default function BackgroundSyncInfo(props) {
    const [open, setΟpen] = useState(false);
    const [data, setData] = useState(null);

    navigator.serviceWorker.onmessage = (event) => {
        console.log("Message recieved:" + JSON.stringify(event.data));
        setData(event.data);
        setΟpen(true);
      };

    const handleClose = () => {
        setΟpen(false);
    };

  return (
        data &&
            <Dialog open={open} aria-labelledby="form-dialog-title" className="order-confirmation">
                <DialogTitle disableTypography id="form-dialog-title">
                    <IconButton aria-label="close"  style={{ color: 'white', position: 'absolute', right: '10px', top: '10px' }} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className="message">
                    <CheckRoundedIcon />
                    <p className="message-1">
                        Η παραγγελίες που είχαν αποθηκευτεί προσωρινά, έχουν καταχωρηθεί με επιτυχία!
                    </p>
                    <p className="message-2">Ο τελικός αριθμός πόντων είναι</p>
                    <p className="message-points">{data.currentPoints}</p>
                    <p className="message-3">Απομένουν ακόμα {data.nextLevelRemainingPoints} πόντοι για να γίνεις {data.nextLevel}</p>
                </DialogContent>
            </Dialog>
        
  );
}
