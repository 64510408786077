import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import Accordion  from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Box from "@material-ui/core/Box";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import Link from "@material-ui/core/Link";

import { salesmanService } from "_services/salesman.service";
import { salesService } from "_services/sales.service";
import { Typography } from "@material-ui/core";

function DeleteDialog(props) {
  const handleClose = (action) => {
    props.handleDialogAction(action, props.transactionId);
  };
  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Διαγραφή παραγγελίας"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Η διαγραφή της παραγγελίας θα είναι οριστική και δεν υπάρχει
          δυνατότητα επαναφοράς της. Διαγράψτε την, αν μόνο είσαστε σίγουροι για
          την ενέργειά σας.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(1)}>ΔΙΑΓΡΑΦΗ</Button>
        <Button onClick={() => handleClose(0)} autoFocus>
          ΑΚΥΡΩΣΗ
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function PostDeleteDialog(props) {
  const [open, setOpen] = React.useState(props.open);

  React.useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Διαγραφή παραγγελίας"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.dialog ? props.dialog : "Η διαγραφή ολοκληρώθηκε με επιτυχία."}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>OK</Button>
      </DialogActions>
    </Dialog>
  );
}

export class OrdersHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      periodId: 0,
      periods: [],
      periodData: [],
      loading: true,
      OpenDeleteDialog: false,
      transactionId: 0,
      OpenPostDeleteDialog: false
    };
    this.handleSalesPeriodChange = this.handleSalesPeriodChange.bind(this);
    this.handleEditOrder = this.handleEditOrder.bind(this);
    this.handleDeleteOrder = this.handleDeleteOrder.bind(this);
    this.handleDialogAction = this.handleDialogAction.bind(this);
  }

  componentDidMount() {
    this.populateOrdersHistoryData(0);
  }

  handleSalesPeriodChange = (event, index) => {
    if (this.state.periodId != event.target.value) {
      this.populateOrdersHistoryData(event.target.value);
    }
  };

  async populateOrdersHistoryData(periodId) {
    try {
      const response = await salesmanService.getOrdersHistoryData(periodId);
      const data = await response;
      if (this.state.periods.length == 0) {
        this.setState({
          periods: data.periods
        });
      }
      this.setState({
        periodData: data.monthlyTransactions,
        loading: false,
        periodId: data.selectedPeriod
      });
    } catch (error) {
      this.setState({ error: error });
    }
  }

  handleEditOrder(transactionId) {
    const { history } = this.props;
    if (history) history.push("/order", { transactionId: transactionId });
  }

  handleDeleteOrder(transactionId) {
    this.setState({ OpenDeleteDialog: true, transactionId: transactionId });
  }

  async handleDialogAction(action, trasactionId) {
    if (action === 1) {
      const response = await salesService.deleteOrder(trasactionId);
      const data = await response;
      if (data) {
        this.populateOrdersHistoryData(this.state.periodId);
        this.setState({ OpenDeleteDialog: false, OpenPostDeleteDialog: true });
      }
    } else this.setState({ OpenDeleteDialog: false });
  }

  renderOrder(order, orderIdx) {
    let orderDate = parseISO(order.transactionDate);
    let orderDateInfo = (
      <Box className="transactiondate" pr={4}>
        <Box className="date">{format(orderDate, "dd/MM/yyyy")}</Box>
        <Box className="titme">{format(orderDate, "hh:mm")}</Box>
      </Box>
    );

    return (
      <Box
        minWidth={{ xs: 100, sm: 300 }}
        display="flex"
        key={orderIdx}
        className="transaction"
        height={78}
        m={1}
        p={2}
        width="100%"
      >
        {orderDateInfo}
        <AvatarGroup max={3}>
          {order.productImages.map((productUrl, index) => (
            <Avatar
              alt=""
              src={productUrl + "?w=30&h=30&mode=max"}
              key={index}
            />
          ))}
        </AvatarGroup>
        <Box display="flex">
          <IconButton
            edge="end"
            aria-label="edit"
            onClick={() => this.handleEditOrder(order.transactionId)}
          >
            <EditIcon style={{ fill: "white" }} />
          </IconButton>
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={() => this.handleDeleteOrder(order.transactionId)}
          >
            <DeleteIcon style={{ fill: "white" }} />
          </IconButton>
        </Box>
      </Box>
    );
  }

  renderOrdersHistory(periods, periodData) {
    var getMonthName = function (idx) {
      switch (idx) {
        case 1:
          return "Ιανουάριος";
        case 2:
          return "Φεβρουάριος";
        case 3:
          return "Μάρτιος";
        case 4:
          return "Απρίλιος";
        case 5:
          return "Μάιος";
        case 6:
          return "Ιούνιος";
        case 7:
          return "Ιούλιος";
        case 8:
          return "Αύγουστος";
        case 9:
          return "Σεπτέμβριος";
        case 10:
          return "Οκτώβριος";
        case 11:
          return "Νοέμβριος";
        case 12:
          return "Δεκέμβριος";
        default:
          return "---";
      }
    };
    return (
      <Grid container item alignItems="stretch" className="sales-container">
        <Typography variant="h1">Οι πωλήσεις μου</Typography>
        <Box className="period-selector">
          <InputLabel id="salesProfile-Period-label">
            Πωλήσεις περιόδου:
          </InputLabel>
          <Select
            labelId="salesProfile-Period-label"
            id="salesProfile-Period"
            value={this.state.periodId}
            onChange={this.handleSalesPeriodChange}
          >
            {periods.map((period, index) => (
              <MenuItem key={index} value={period.periodId}>{period.longName}</MenuItem>
            ))}
          </Select>
        </Box>
        <Box className="transactions-accordion">
          {
          periodData.length > 0 ?
          periodData.map((periodMonth, index) => (
            <Accordion key={index} defaultExpanded={index === 0}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {getMonthName(periodMonth.month)}
              </AccordionSummary>
              <AccordionDetails>
                <Grid
                  container
                  item
                  alignItems="stretch"
                  className="sales-container"
                >
                  {periodMonth.transactions.map((order, orderIdx) => {
                    return this.renderOrder(order, orderIdx);
                  })}
                </Grid>
              </AccordionDetails>
            </Accordion>
          )) :
          "Δεν έχετε καταχωρημένες πωλήσεις για αυτή τη περίοδο"
          }
        </Box>
      </Grid>
    );
  }

  render() {
    if (this.state.error) throw new Error(this.state.error);

    let contents = "";
    if (this.state.loading)
      contents = (
        <div className="progress-container">
          <CircularProgress />
        </div>
      );
    else if (!this.state.periods || this.state.periods.length === 0)
      contents = (
        <Alert severity="warning">
          <Link to="/home">Καταχώρησε την πρώτη σου πώληση</Link> και επισκέψου
          ξανά το ιστορικό πωλήσεων για να τη δεις.
        </Alert>
      );
    else
      contents = this.renderOrdersHistory(
        this.state.periods,
        this.state.periodData
      );

    return (
      <Grid
        container
        item
        alignItems="center"
        alignContent="center"
        className="order-history"
        xs={12}
      >
        <Grid item xs={12}>
          {contents}
          <DeleteDialog
            open={this.state.OpenDeleteDialog}
            transactionId={this.state.transactionId}
            handleDialogAction={(action, transactionId) =>
              this.handleDialogAction(action, transactionId)
            }
          />
          <PostDeleteDialog open={this.state.OpenPostDeleteDialog} />
        </Grid>
      </Grid>
    );
  }
}
