import Cookies from "universal-cookie";
import { Notifier } from "@airbrake/browser";

export function handleResponse(response) {
  return response.text().then((text) => {
    let data = null;
    try {
      data = text && JSON.parse(text);
    } catch (err) {
      let airbrake = new Notifier({
        projectId: 278527,
        projectKey: "bcb610289dee895520655ecadcb24002",
      });
      let userStr = localStorage.getItem("currentUser");
      let user = "anonymous";
      if (userStr) {
        user = JSON.parse(userStr).fullName ?? "";
      }
      airbrake.notify({
        error: err,
        params: { text: text, user: user, location: "handleResponse - json parsing" },
      });
    }
    if (!response.ok) {
      let error =
        (data && data.message) ||
        (data && data.error_description) ||
        response.statusText;
      let userStr = localStorage.getItem("currentUser");
      let user = "anonymous";
      if (userStr) {
        user = JSON.parse(userStr).fullName ?? "";
      }
      let airbrake = new Notifier({
        projectId: 278527,
        projectKey: "bcb610289dee895520655ecadcb24002",
      });
      airbrake.notify({
        error: error,
        params: { response: response, user: user, location: "handleResponse - !response.ok" },
      });

      if ([401, 403].indexOf(response.status) !== -1) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        window.location.href = "/logout";
      } else {
        return Promise.reject(error);
      }
    }
    // for xsrfToken updates
    if (data && data.xsrfToken) {
      var date = new Date();
      date.setDate(date.getDate() + 10);
      const cookies = new Cookies();
      cookies.set("xsrfToken", data.xsrfToken, {
        path: "/",
        sameSite: "strict",
        expires: date,
        secure: !window.location.href.startsWith("http://localhost"),
      });

      // sent token to service worker
      if (navigator.serviceWorker) {
        let workerxsrfToken = { xsrfToken: data.xsrfToken };
        navigator.serviceWorker.ready.then((registration) => {
          registration.active.postMessage(
            "update:" + JSON.stringify(workerxsrfToken)
          );
        });
      }

      delete data.xsrfToken;
    }
    return data;
  });
}
