import React, { Component } from 'react';
import ReactCodeInput from 'react-code-input';
import Button from '@material-ui/core/Button';
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";

import { authenticationService } from "_services/authentication.service";

export class SMSCodeField extends Component {

    constructor(props) {
        super(props);
        this.state = { code : "", error : ""};
        this.onChange = this.onChange.bind(this);
        this.next = this.next.bind(this);
    }

    onChange(value) {
        this.setState({ code : value });
    }

    next(e) {

        if (this.state.code.length === 6)
        {
            authenticationService.validateSMSCode(this.props.smsToken, this.state.code)
            .then(status => {
                if (status) {
                    this.props.handleSmsCodeChange(this.state.code);
                    this.props.nextStep();
                } else {
                    this.setState({ error : "Ο κωδικός δεν είναι σωστός" });
                }
            });
        }
    }

    render() {

        return (
            <Box justifyContent="center" alignItems="center" className="one-time-code" pb={1} pt={2} flexGrow={1}>
                <ReactCodeInput type='number' fields={6} onChange={this.onChange} inputmode="numeric" inputStyle={{
                  fontFamily: 'Roboto',
                  borderRadius: '6px',
                  border: '1px solid lightgrey',
                  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 10px 0px',
                  margin: '4px',
                  padding: '4px',
                  width: '36px',
                  height: '42px',
                  fontSize: '32px',
                  boxSizing: 'border-box',
                  color: 'black',
                  backgroundColor: 'white',
                  textAlign: 'center',
                  fontWeight: 'lighter'
                }} />
                <Button type="submit" fullWidth variant="contained" color="primary" className={this.props.classes.submit} onClick={this.next}>
                    ΣΥΝΕΧΕΙΑ
                </Button>
                {(this.state.error !== "") && <Alert severity="error">{this.state.error}</Alert>}
            </Box>
        )
    }
}

export default SMSCodeField;
