import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
/* import Container from '@material-ui/core/Container'; */
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import { CookieBanner } from "@palmabit/react-cookie-law";

const useStyles = makeStyles((theme) => ({
  /*  submit: {
    margin: theme.spacing(3, 0, 2),
  }, */
}));

export const Home = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [online, setOnline] = useState(navigator.onLine);

  const handleNetworkChange = () => {
    setOnline(navigator.onLine);
  };

  useEffect(() => {
    window.addEventListener("offline", handleNetworkChange);
    window.addEventListener("online", handleNetworkChange);

    return () => {
      window.removeEventListener("offline", handleNetworkChange);
      window.removeEventListener("online", handleNetworkChange);
    };
  }, []);

  const go2Login = () => {
    if (history) history.push("/login");
  };

  const go2Activation = () => {
    if (history) history.push("/activate");
  };

  return (
    <React.Fragment>
      <Grid item xs={12} sm={6} lg={3} className="card-container">
        <Card>
          <CardContent>
            <Typography variant="h1" className="home-title">
              Καλωσήλθες στο <span>Neutrogena Bonus Club</span>
            </Typography>
            <Box component="div" display={online ? "none" : "block"}>
              <Alert severity="warning">
                Δεν μπορείτε να κάνετε login αν δεν υπάρχει σύνδεση με το
                δίκτυο. Συνδεθείτε με το δίκτυο και προσπαθήστε ξανά.
              </Alert>
            </Box>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={go2Login}
              disabled={!online}
            >
              ΕΧΩ ΛΟΓΑΡΙΑΣΜΟ
            </Button>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              className={classes.submit}
              onClick={go2Activation}
              disabled={!online}
            >
              ΕΝΕΡΓΟΠΟΙΗΣΗ ΛΟΓΑΡΙΑΣΜΟΥ
            </Button>
          </CardContent>
        </Card>
        <Box p={{ xs: 2, sm: 3, md: 4 }} textAlign="center">
          <Grid item xs={12} sm={8} lg={6} className="support homesupport card-container">
            <Typography className="call-label">Τηλέφωνο υποστήριξης</Typography>
            <a className="phone-number" href="tel:00302100108950">
              210 010 8950
            </a>
            <Typography className="support-hours">
              Ωράριο λειτουργίας
              <br />
              Δευτέρα έως Παρασκευή: 09:00 - 17:00
            </Typography>
          </Grid>
        </Box>
      </Grid>
      <CookieBanner
        title="Νοιαζόμαστε για τα προσωπικά σας δεδομένα"
        message="Χρησιμοποιούμε cookies τα οποία επιτρέπουν την σωστή λειτουργία της ιστοσελίδας μας. Για περισσότερες πληροφορίες παρακαλούμε να επισκεφτείτε την πολιτική cookies."
        policyLink="/cookies-policy"
        privacyPolicyLinkText="Πολιτική cookies"
        onAccept={() => {}}
        showPreferencesOption={false}
        showStatisticsOption={false}
        showMarketingOption={false}
        dismissOnScroll={false}
        necessaryOptionText="Απαραίτητα cookies"
        acceptButtonText="Ενημερώθηκα"
        styles={{
          button: {
            background:
              "transparent linear-gradient(281deg, #B76FFF 0%, #66B2FD 100%) 0% 0% no-repeat padding-box",
            padding: "10px",
            borderRadius: "20px",
            borderStyle: "none",
            color: "#FFFFFF",
          },
          message: {
            padding: "10px",
          },
          dialog: {
            position: "fixed",
            bottom: "0px",
            left: "0px",
            right: "0px",
            zIndex: 100000,
            backgroundColor: "rgb(248, 247, 247)",
            padding: "10px",
            borderTop: "1px solid #eeeeee",
          },
        }}
      />
    </React.Fragment>
  );
};
