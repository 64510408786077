import { authFetch } from "_helpers/authFetch";

export const salesmanService = {
  getSalesmanInitials,
  getUserProfileData,
  editProfile,
  getSalesProfileData,
  getOrdersHistoryData,
  getSalesmanFullaName,
  getRanking,
  PointsHistoryData,
};

async function getSalesProfileData(selectedPeriodId) {
  const requestOptions = {
    credentials: "include",
    method: "GET",
  };

  return await authFetch(
    `${process.env.REACT_APP_MERCURYURL}api/salesmen/getprofile?selectedPeriodId=${selectedPeriodId}`,
    requestOptions
  ).then((profile) => {
    return profile;
  });
}

async function editProfile(firstName, lastName, email, mobilePhone) {
  const requestOptions = {
    method: "POST",
    credentials: "include",
    body:
      "email=" +
      email +
      "&mobilePhone=" +
      mobilePhone +
      "&firstName=" +
      firstName +
      "&lastName=" +
      lastName,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  return await authFetch(
    `${process.env.REACT_APP_MERCURYURL}api/salesmen/PostUserProfileUpdate`,
    requestOptions
  ).then((status) => {
    return status;
  });
}

async function getOrdersHistoryData(period) {
  const requestOptions = {
    method: "GET",
    credentials: "include",
  };

  return await authFetch(
    `${process.env.REACT_APP_MERCURYURL}api/salesmen/GetTransactionsPerPeriod?id=${period}`,
    requestOptions
  ).then((orders) => {
    return orders;
  });
}

async function getUserProfileData(period) {
  const requestOptions = {
    method: "GET",
    credentials: "include",
  };

  return await authFetch(
    `${process.env.REACT_APP_MERCURYURL}api/salesmen/GetUserProfile`,
    requestOptions
  ).then((userProfile) => {
    return userProfile;
  });
}

async function getRanking() {
  const requestOptions = {
    method: "GET",
    credentials: "include",
  };

  return await authFetch(
    `${process.env.REACT_APP_MERCURYURL}api/salesmen/GetRanking?loyaltyProgramId=${process.env.REACT_APP_MERCURYLOYALTYPROGRAMID}`,
    requestOptions
  ).then((ranking) => {
    return ranking;
  });
}

async function PointsHistoryData() {
  const requestOptions = {
    method: "GET",
    credentials: "include",
  };

  return await authFetch(
    `${process.env.REACT_APP_MERCURYURL}api/salesmen/GetPointHistory?loyaltyProgramId=${process.env.REACT_APP_MERCURYLOYALTYPROGRAMID}`,
    requestOptions
  );
}

function getSalesmanInitials() {
  let userStr = localStorage.getItem("currentUser");
  if (!userStr) return "";
  else {
    let userData = JSON.parse(userStr);
    return userData.initials ?? "";
  }
}

function getSalesmanFullaName() {
  let userStr = localStorage.getItem("currentUser");
  if (!userStr) return "";
  else {
    let userData = JSON.parse(userStr);
    return userData.fullName ?? "";
  }
}
