import React from "react";
import { withRouter } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Slider from "react-slick";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { ReactComponent as ConsultantIcon } from "../../images/svg/consultant.svg";
import { ReactComponent as ExpertIcon } from "../../images/svg/expert.svg";
import { ReactComponent as AmbassadorIcon } from "../../images/svg/ambassador.svg";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import Alert from '@material-ui/lab/Alert';
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";


import { salesmanService } from "_services/salesman.service";
import { salesService } from "_services/sales.service";

function DeleteDialog(props) {
  const handleClose = (action) => {
    props.handleDialogAction(action, props.transactionId);
  };
  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Διαγραφή παραγγελίας"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Η διαγραφή της παραγγελίας θα είναι οριστική και δεν υπάρχει
          δυνατότητα επαναφοράς της. Διαγράψτε την, αν μόνο είσαστε σίγουροι για
          την ενέργειά σας.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(1)}>ΔΙΑΓΡΑΦΗ</Button>
        <Button onClick={() => handleClose(0)} autoFocus>
          ΑΚΥΡΩΣΗ
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function PostDeleteDialog(props) {
  const [open, setOpen] = React.useState(props.open);

  React.useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Διαγραφή παραγγελίας"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.dialog ? props.dialog : "Η διαγραφή ολοκληρώθηκε με επιτυχία."}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>OK</Button>
      </DialogActions>
    </Dialog>
  );
}

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    let emptyData = {
      loyaltyPeriods: [],
      fullname: "",
      initials: "",
      periodPoints: [],
      recentTransactions: [],
      badges: [],
    };
    this.handleEditOrder = this.handleEditOrder.bind(this);
    this.handleDeleteOrder = this.handleDeleteOrder.bind(this);
    this.state = { loading: true, period: 0, periodData: emptyData, OpenDeleteDialog:false, OpenPostDeleteDialog : false };
  }

  handleEditOrder(transactionId) {
    const { history } = this.props;
    if (history) history.push("/order", { transactionId: transactionId });
  }

  handleDeleteOrder(transactionId) {
    this.setState({ OpenDeleteDialog: true, transactionId: transactionId });
  }

  async handleDialogAction(action, trasactionId) {
    if (action === 1) {
      const response = await salesService.deleteOrder(trasactionId);
      const data = await response;
      if (data) {
        this.populateSalesProfileData(this.state.period);
        this.setState({ OpenDeleteDialog: false, OpenPostDeleteDialog: true });
      }
    } else this.setState({ OpenDeleteDialog: false });
  }

  componentDidMount() {
    this.populateSalesProfileData(0);
  }

  handleSalesPeriodChange = (event) => {
    this.setState({ period: event.target.value });
  };

  renderSalesProfile(periodData) {
    var settings = {
        arrows: false,
        dots: false,
        infinite: false,
        speed: 500,
        swipe: true,
        slidesToShow: 6,
        slidesToScroll: 6,
        centerMode: false,
        variableWidth: true,
        focusOnSelect: false,
        responsive: [
          {
            breakpoint: 1260,
            settings: {
              centerMode: false,
              slidesToShow: 6,
              slidesToScroll: 6,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              centerMode: false,
              slidesToShow: 6,
              slidesToScroll: 6,
            },
          },
          {
            breakpoint: 600,
            settings: {
              centerMode: false,
              slidesToShow: 6,
              slidesToScroll: 6,
            },
          },
          {
            breakpoint: 480,
            settings: {
              centerMode: false,
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
        ]};

    return (
      <Grid
        container
        item
        alignItems="center"
        alignContent="center"
        className="dashboard"
        xs={12}
      >
        <Grid item  className="card-container">
          <Avatar alt={periodData.fullname} className="large-avatar">
            {periodData.initials}
          </Avatar>
          <Typography variant="h3" className="fullname">
            {periodData.fullname}
          </Typography>
          <Card>
            <CardContent>
              <Typography variant="h2">Η βαθμολογία μου:</Typography>

              <InputLabel
                id="salesProfile-Period-label"
                className="label"
                shrink={false}
              >
                ΠΕΡΙΟΔΟΣ:
              </InputLabel>
              <Select
                labelId="salesProfile-Period-label"
                id="salesProfile-Period"
                value={this.state.period}
                onChange={this.handleSalesPeriodChange}
                autoWidth={false}
                style={{ width: "100%" }}
              >
                {periodData.loyaltyPeriods.map((period, index) => (
                  <MenuItem key={period.periodId} value={index}>
                    {period.longName}
                  </MenuItem>
                ))}
              </Select>
              <Grid container justify="space-around">
                <Grid item xs={6}>
                  <Paper elevation={0}>
                    <Typography variant="h4" className="label">
                      ΠΟΝΤΟΙ
                    </Typography>
                    <Typography className="points">
                      {periodData.periodPoints[this.state.period].totalPoints}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper elevation={0}>
                    <Typography variant="h4" className="label">
                      Μ.Ο. / ΗΜΕΡΑ
                    </Typography>
                    <Typography className="points">
                      {periodData.periodPoints[
                        this.state.period
                      ].averagePointsPerDay.toFixed(2)}
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} >
          {this.state.periodData.badges.length === 0 ?
            <Typography variant="h2">Δεν έχεις διακρίσεις ακόμη!</Typography> :
            <Typography variant="h2">Οι διακρίσεις μου:</Typography>
          } 
          
          <Slider className="badges-list" {...settings}>
            {this.state.periodData.badges.map((badge, index) => (
              <Box key={index}>
                <div className={"badge " + (badge.iconURL + ".").split(".")[0]}>
                  {
                    {
                      "ambassador.svg": <AmbassadorIcon />,
                      "expert.svg": <ExpertIcon />,
                      "consultant.svg": <ConsultantIcon />,
                    }[badge.iconURL]
                  }
                </div>
                <div className="title">{badge.badgeTitle}</div>
              </Box>
            ))}
          </Slider>
        
        </Grid>

        <Grid container item alignItems="stretch" className="sales-container">
          <Typography variant="h2">Πρόσφατες πωλήσεις:</Typography>
          {this.state.periodData.recentTransactions.map((order, orderIdx) => (
            <Box
              minWidth={{ xs: 100, sm: 300 }}
              display="flex"
              key={orderIdx}
              className="transaction"
              height={78} m={1} p={2}
              width="100%"
            >
              <Box className="transactiondate" pr={4}>
                <Box className="date">
                  {format(parseISO(order.transactionDate), "dd/MM/yyyy")}
                </Box>
                <Box className="titme">
                  {format(parseISO(order.transactionDate), "hh:mm")}
                </Box>
              </Box>
              <AvatarGroup max={3}>
                {order.productImages.map((productUrl, index) => (
                  <Avatar
                    alt=""
                    src={productUrl + "?w=30&h=30&mode=max"}
                    key={index}
                  />
                ))}
              </AvatarGroup>
              <Box display="flex">
                <IconButton
                  edge="end"
                  aria-label="edit"
                  onClick={() => this.handleEditOrder(order.transactionId)}
                >
                  <EditIcon style={{fill: "white"}}/>
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => this.handleDeleteOrder(order.transactionId)}
                >
                  <DeleteIcon style={{fill: "white"}}/>
                </IconButton>
              </Box>
            </Box>
          ))}
          <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              onClick={() => this.props.history.push("/ordershistory")}
            >
              ΟΛΕΣ ΟΙ ΠΩΛΗΣΕΙΣ
            </Button> 
            <DeleteDialog
              open={this.state.OpenDeleteDialog}
              transactionId={this.state.transactionId}
              handleDialogAction={(action, transactionId) =>
                this.handleDialogAction(action, transactionId)
              }
            />
            <PostDeleteDialog open={this.state.OpenPostDeleteDialog} />
        </Grid>
        
      </Grid>
    );
  }

  async populateSalesProfileData(period) {
    try {
      const response = await salesmanService.getSalesProfileData(period);
      const data = await response;
      this.setState({ periodData: data, loading: false, period: period });
    } catch (error) {
      this.setState({ error: error });
    }
  }

  render() {
    if (this.state.error) throw new Error(this.state.error);

    let contents = "";
    
    if (this.state.loading) {
      contents = (
        <div className="progress-container"><CircularProgress /></div>
      );
    } else if (this.state.periodData.periodPoints === undefined || this.state.periodData.periodPoints.length === 0)
    {
      contents = (
        <Alert severity="warning">
          Άρχισε να καταχωρείς πωλήσεις και θα δεις τους πόντους σου να ανεβαίνουν!
          </Alert> 
      );
    }
    else 
      contents = (
        this.renderSalesProfile(this.state.periodData)
      );

    return (
        <React.Fragment>
        {contents}
        </React.Fragment>
    );
  }
}

export default withRouter(Dashboard);