import React from "react";
import Cookies from 'universal-cookie';
import { useHistory } from "react-router-dom";
import { authenticationService } from "_services/authentication.service";

export const Logout = (props) => {
    const history = useHistory();
    
    authenticationService.logout();

    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('smsToken');
    localStorage.removeItem('currentStore');
    localStorage.removeItem('remenberme');
    const cookies = new Cookies();
    cookies.remove('xsrfToken');

    // sent token to service worker
    if (navigator.serviceWorker) {
        navigator.serviceWorker.ready.then( registration => {
            registration.active.postMessage("delete:");
        });
    }
    
    setTimeout(function(){ 
        props.successLogout();
        history.push("/home");
     }, 1000);
    

    return null;
}