import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { withRouter } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import StepWizard from "react-step-wizard";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import EmailIcon from "@material-ui/icons/Email";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";

import { authenticationService } from "_services/authentication.service";
import { SMSCodeField } from "components/account/common/SMSCodeField";
import { PasswordWithConfirmField } from "components/account/common/PasswordWithConfirmField";

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  wizard: {
    marginTop: 50
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

const PageOne = (props) => {
  
  const validationSchema = Yup.object({
    phoneNumber: Yup.string().matches("^69[0-9]{8}$", "Λανθασμένος αριθμός κινητού τηλεφώνου. Ο αριθμός πρέπει να αρχίζει από 69 και να έχει 10 ψηφία."),
    email: Yup.string().email(),
    PhoneOrEmail: Yup.bool().when(["phoneNumber", "email"], {
      is: (phoneNumber, email) =>
        (!phoneNumber && !email) || (!!phoneNumber && !!email),
      then: Yup.bool().required(
        "Πρέπει να συμπληρώσετε είτε το κινητό, είτε το email."
      ),
      otherwise: Yup.bool(),
    }),
  });

 const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    isSubmitting,
    status,
  } = useFormik({
    initialValues: {
      phoneNumber: "",
      email: "",
    },
    validationSchema,
    onSubmit(values, { setStatus, setSubmitting }) {
      setStatus();
      authenticationService
        .sendPasswordResetVerificationSMS(values.phoneNumber, values.email)
        .then(
          (smsToken) => {
            props.handleValidMobileOrEmail(smsToken);
            setSubmitting(false);
            props.nextStep();
          },
          (error) => {
            let msg = "Παρακαλώ επαναλάβετε την διαδικασία.";
            if (error === 'User Not found or found more than once.')
              msg = "Δεν βρέθηκε χρήστης με αυτά τα στοιχεία. " + msg;
            else if (error) msg = error.trim() + " " + msg;

            setStatus(msg);
            setSubmitting(false);
          }
        );
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      {errors.phoneNumber && (
        <Alert severity="error">{errors.phoneNumber}</Alert>
      )}
      {errors.email && <Alert severity="error">{errors.email}</Alert>}
      {errors.PhoneOrEmail && (
        <Alert severity="error">{errors.PhoneOrEmail}</Alert>
      )}
      {status && <Alert severity="error">{status}</Alert>}
      <Typography variant="body1">Συμπληρώστε email ή κινητό</Typography>
      <Box
        display="flex"
        alignContent="stretch"
        alignItems="flex-end"
        pb={1}
        pt={2}
      >
        <Box pr={1}>
          <PhoneIphoneIcon />
        </Box>
        <Box flexGrow={1}>
          <TextField
            fullWidth
            label="Κινητό"
            id="phoneNumber"
            name="phoneNumber"
            type="text"
            onChange={handleChange}
            value={values.phoneNumber}
            helperText={
              errors.phoneNumber && touched.phoneNumber
                ? errors.phoneNumber
                : ""
            }
            error={
              errors.phoneNumber && touched.phoneNumber ? "error" : undefined
            }
          />
        </Box>
      </Box>

      <Box
        display="flex"
        alignContent="stretch"
        alignItems="flex-end"
        pb={1}
        pt={2}
      >
        <Box pr={1}>
          <EmailIcon />
        </Box>
        <Box flexGrow={1}>
          <TextField
            fullWidth
            label="Email"
            id="email"
            name="email"
            type="text"
            onChange={handleChange}
            value={values.email}
            helperText={errors.email && touched.email ? errors.email : ""}
            error={errors.email && touched.email ? "error" : undefined}
          />
        </Box>
      </Box>

      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={props.classes.submit}
        disabled={isSubmitting}
      >
        ΣΥΝΕΧΕΙΑ
      </Button>
    </form>
  );
};

const FailurePage = (props) => {
  return (
    <div className="form-group">
      <Alert severity="error">
        Η διαδικασία δεν ολοκληρώθηκε. {props.msg}
      </Alert>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        onClick={props.firstStep}
      >
        ΠΡΟΣΠΑΘΗΣΤΕ ΞΑΝΑ
      </Button>
    </div>
  );
};

class ResetPassword extends React.Component {
  redirectToLogin = () => {
    const { history } = this.props;
    if (history) history.push("/login");
  };

  constructor(props) {
    super(props);
    this.state = {
      smsToken: "",
      smsCode: "",
      password: "",
      status: "activateForm",
      resetError: "",
    };
    this.handleValidMobileOrEmail = this.handleValidMobileOrEmail.bind(this);
    this.handleSmsCodeChange = this.handleSmsCodeChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
  }

  handleValidMobileOrEmail(updatedSmsToken) {
    this.setState({ smsToken: updatedSmsToken.access_token });
    this.setState({ smsCode: "" });
  }

  handleSmsCodeChange(updatedSmsCode) {
    this.setState({ smsCode: updatedSmsCode });
    this.setState({ password: "" });
  }

  handlePasswordChange(updatedPassword) {
    this.setState({ password: updatedPassword });

    return authenticationService
      .activateAccount(
        this.state.smsToken,
        this.state.smsCode,
        this.state.password
      )
      .then(
        (status) => {
          if (status) {
            localStorage.removeItem("rememberme");
            localStorage.removeItem("cart");
            this.props.successReset();
            this.redirectToLogin();
            return true;
          } else {
            this.setState({ resetError: status });
            return false;
          }
        },
        (error) => {
          let msg =
            "Παρουσιάστηκε σφάλμα. Παρακαλώ επαναλάβετε την διαδικασία.";
          if (error) {
            if (error === "User Not found or found more than once.") {
              msg =
                "Δεν υπάρχει λογαριασμός στο σύστημα με το κινητό που συμπληρώσατε.";
            } else {
              msg = error.trim() + " " + msg;
            }
          }
          this.setState({ resetError: msg });
          return false;
        }
      );
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid item xs={12} sm={6} lg={3} className="card-container">
        <Card>
          <CssBaseline />
          <CardContent>
            <Grid container item className={classes.paper}>
              <Typography gutterBottom variant="h2">
                Αλλαγή κωδικού πρόσβασης
              </Typography>
              <StepWizard className={["full-width", classes.wizard].join(' ')}>
                <PageOne flexGrow={1}
                  handleValidMobileOrEmail={this.handleValidMobileOrEmail}
                  classes={classes}
                />
                <SMSCodeField flexGrow={1}
                  handleSmsCodeChange={this.handleSmsCodeChange}
                  classes={classes} smsToken={this.state.smsToken}
                />
                <PasswordWithConfirmField flexGrow={1}
                  handlePasswordChange={this.handlePasswordChange}
                  classes={classes}
                />
                <FailurePage msg={this.state.resetError} />
              </StepWizard>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles)(ResetPassword));
