import React, { Fragment, useState } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { withRouter } from "react-router-dom";
import Slider from "react-slick";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Box from "@material-ui/core/Box";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import CloseIcon from "@material-ui/icons/Close";
import { CardActions } from "@material-ui/core";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import { InputAdornment } from "@material-ui/core";
import elLocale from "date-fns/locale/el";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import CircularProgress from "@material-ui/core/CircularProgress";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ScrollMenu from 'react-horizontal-scrolling-menu';


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { salesService } from "_services/sales.service";


const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  counter: {
    "text-align": "center",
  },
  productCard: {
    display: "block",
    width: 277,
    height: 364,
  },
  productCardContent: {
    "text-align": "center",
    "& img": {
      margin: "0 auto",
    },
  },
  productTitle: {
    minHeight: 80,
  },
});

// One item component
// selected prop will be passed
const MenuItem = ({text, selected}) => {
  return <div
    className={`category ${selected ? 'selected' : ''}`}
    >{text}</div>;
};
 
 
const Arrow = ({ text, className }) => {
  return (
    <div
      className={className}
    ><span>{text}</span></div>
  );
};
 
 
const ArrowLeft = Arrow({ text: '<', className: 'arrow-prev' });
const ArrowRight = Arrow({ text: '>', className: 'arrow-next' });
 


const ProductPopup = (props) => {
  const { classes } = props;
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Dialog
      open={props.open}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: { borderRadius: 20, maxWidth: 1072 },
      }}
    >
      <DialogTitle style={{padding:0, height:0}} disableTypography id="form-dialog-title">
        <IconButton
          aria-label="close"
          style={{
            color: "white",
            backgroundColor: "#58595B",
            position: "absolute",
            right: "10px",
            top: "10px",
            width: "25px",
            height: "25px",
          }}
          onClick={props.handleCloseProductPopup}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="product-container"
        style={{
          margin: 0,
          padding: 0,
        }}
      >
        <div className="product-header">
          <Typography
            style={{
              fontSize: "25px",
              fontWeight: 200,
              lineHeight: "35px",
              padding: "20px",
            }}
          >
            {props.data ? props.data.name : "title"}
          </Typography>
          <div
            style={{
              margin: "30px auto",
            }}
          >
            <img
              style={{
                margin: "0 auto",
                display: "block",
              }}
              src={
                (props.data ? props.data.photoUrl : "dommy.png") +
                "?w=260&h=260&mode=max"
              }
              alt={props.data ? props.data.name : "title"}
            />
          </div>
        </div>
        <div className="product-details">
          <Accordion elevation={0} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="neutrogena-gradient"
              style={{height: "50px", minHeight: "50px" }}
            >
              <Typography>Περιγραφή προϊόντος</Typography>
            </AccordionSummary>
            <AccordionDetails >
              <div style={{ color: "#1A1919" }}
              dangerouslySetInnerHTML={{ __html: props.data? props.data.description : "" }}>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary
              className="neutrogena-gradient"
              expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              aria-controls="panel2a-content"
              id="panel2a-header"
              style={{height: "50px", minHeight: "50px" }}
            >
              <Typography>Οδηγίες χρήσης</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ color: "#1A1919" }}
              dangerouslySetInnerHTML={{ __html: props.data? props.data.additionalText1:"" }}
              >
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </DialogContent>
    </Dialog>
  );
};

class ProductList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      products: [],
      loading: true,
      errorMsg: "",
      productIndex: 1,
      error: undefined,
      showProductPopup: false,
      selectedCategory: 0,
      productCategoriesMenu: null
    };
    this.handleCloseProductPopup = this.handleCloseProductPopup.bind(this);
    //this.handleOpenProductPopup = this.handleOpenProductPopup.bind(this);
  }

  onSelectCategory = key => {
    if (this.state.selectedCategory !== key) {
      this.populateProductsBySelectedCategory(key);
      this.setState({ selectedCategory: key });
    }
  }

  handleCloseProductPopup() {
    this.setState({
      showProductPopup: false,
    });
  }

  handleOpenProductPopup(product) {
    this.setState({
      showProductPopup: true,
      selectedProduct: product,
    });
  }

  componentDidMount() {
    if (!this.state.openStoreDialog) {
      if (this.populateProductCategories()) {
        this.populateProductsBySelectedCategory(this.state.selectedCategory);
      }
    }
  }

  getProductbyId(productId) {
    for (let i = 0; i < this.state.products.length; i++) {
      if (this.state.products[i].productId === productId) {
        return i;
      }
    }
    return -1;
  }


  renderProductCarousel(products) {
    const { classes } = this.props;

    return products.map((product, index) => (
      <Card className={classes.productCard} key={product.productId}>
        <CardContent
          className={classes.productCardContent}
          onClick={() => this.handleOpenProductPopup(product)}
        >
          <h2 className={classes.productTitle}>{product.name}</h2>
          <div className="product-image-container">
            <img
              src={product.photoUrl + "?w=160&h=160&mode=max"}
              alt={product.name}
            />
          </div>
        </CardContent>
      </Card>
    ));
  }

  render() {
    if (this.state.error) throw new Error(this.state.error);

    const { classes } = this.props;
    let loading = this.state.loading ? (
      <div className="progress-container">
        <CircularProgress />
      </div>
    ) : (
      ""
    );
    let contents = !this.state.loading
      ? this.renderProductCarousel(this.state.products)
      : "";

    var settings = {
      arrows: true,
      dots: false,
      infinite: false,
      swipe: true,
      centerPadding: "40px",
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1260,
          settings: {
            centerMode: false,
            slidesToShow: 4,
            slidesToScroll: 4,
            arrows: true
          },
        },
        {
          breakpoint: 1024,
          settings: {
            centerMode: false,
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: true
          },
        },
        {
          breakpoint: 800,
          settings: {
            centerMode: false,
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            centerMode: true,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],

      afterChange: (index) => {
        this.setState({ productIndex: index + 1 });
      },
    };



    let productCategoriesMenu = !this.state.loading
    ? this.state.productCategoriesMenu
    : [];

    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Typography variant="h1">Προϊόντα</Typography>
        </Grid>
        <Grid item xs={12} sm={9} lg={9} className="product-categories">
        <ScrollMenu
          data={ productCategoriesMenu }
          arrowLeft={ArrowLeft}
          arrowRight={ArrowRight}
          selected={this.state.selectedCategory}
          onSelect={this.onSelectCategory}
          alignCenter={false}
          hideSingleArrow={true}
        />
        </Grid>


        {loading}
        <Grid item xs={12} sm={12} lg={12} className="counter-container">
          <div className={classes.counter}>
            {!this.state.loading && (
              <div>
                {this.state.productIndex}&nbsp;/&nbsp;
                {this.state.products.length}
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={12} sm={12} lg={12} className="products-container">
          <div>
            <Slider ref={slider => (this.slider = slider)}  className="products-list" {...settings}>
              {contents}
            </Slider>
          </div>
        </Grid>

        <ProductPopup
          open={this.state.showProductPopup}
          handleCloseProductPopup={this.handleCloseProductPopup}
          classes={classes}
          data={this.state.selectedProduct}
        />
      </React.Fragment>
    );
  }


  async populateProductsBySelectedCategory(productCategoryId) {
    if (productCategoryId == 0) return this.populateBestSellerProductData();
    else return this.populateProductsByCategory(productCategoryId);
  }

  async populateProductCategories() {
    try {
      const response = await salesService.getProductCategories(
        `${process.env.REACT_APP_MERCURYPRODUCTCATEGORYROOTID}`
      );
      const data = await response;

      var productCategories = data.map((c) => ({
        categoryId: c.productCategoryId,
        categoryName: c.name,
      }));
      productCategories.unshift({ categoryId: 0, categoryName: "Best Sellers" });
      var menu =  productCategories.map(el => {
        return <MenuItem text={el.categoryName} key={el.categoryId} selected={0} />;
      });
      this.setState({ productCategoriesMenu: menu, selectedCategory: 0 });

      this.slider.slickGoTo(0);

      return true;
    } catch (error) {
      this.setState({ error: error });
      return false;
    }
  }

  async populateProductsByCategory(categoryId) {
    let data = [];
    try {
      const response = await salesService.getProductsByCategory(categoryId);
      data = await response;
    } catch (error) {
      //this.setState({ error: error });
    }
    this.setState({
      products: data,
      loading: false,
      productIndex: 1,
      productCategory: categoryId
    });

    this.slider.slickGoTo(0);
    
  
  }

  async populateBestSellerProductData() {
    let data = [];
    try {
      const response = await salesService.getBestSellerProductsByStore();
      data = await response;
    } catch (error) {
      //this.setState({ error: error });
    }
    this.setState({
      products: data,
      loading: false,
      productIndex: 1,
      productCategory: 0
    });
      this.slider.slickGoTo(0);
  }
}

export default withRouter(withStyles(styles)(ProductList));
