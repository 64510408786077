import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import zxcvbn from "zxcvbn";
import TextField from "@material-ui/core/TextField";
import LockIcon from "@material-ui/icons/Lock";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import PasswordStrengthMeter from "./PasswordStrengthMeter";

export const PasswordWithConfirmField = (props) => {
  const validationSchema = Yup.object({
    password: Yup.string()
      .required("Ο κωδικός είναι υποχρεωτικός")
      .test(
        "PasswordStrength",
        "Ο κωδικός δεν είναι αρκετά ισχυρός.",
        function (value) {
          if (value) {
            let result = zxcvbn(value);
            return result.score >= 3;
          } else return false;
        }
      ),
    passwordConfirmation: Yup.string()
      .required("Συμπλήρωσε τον κωδικό ξανά")
      .oneOf([Yup.ref("password"), null], "Οι κωδικοί πρέπει να είναι ίδιοι"),
  });

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    isSubmitting,
    status,
  } = useFormik({
    initialValues: {
      password: "",
      passwordConfirmation: "",
    },
    validationSchema,
    onSubmit(values, { setStatus, setSubmitting }) {
      setSubmitting(true);
      let asyncActivation = props.handlePasswordChange(values.password);
      setStatus();
      setSubmitting(false);
      asyncActivation.then((result) => {
        if (!result) props.nextStep();
      });
    },
  });
  return (
    <div>
      {errors.password && <Alert severity="error">{errors.password}</Alert>}
      {errors.passwordConfirmation && (
        <Alert severity="error">{errors.passwordConfirmation}</Alert>
      )}
      {status && <Alert severity="error">{status}</Alert>}
      <form onSubmit={handleSubmit}>
        <Box
          display="flex"
          alignContent="stretch"
          alignItems="flex-end"
          pb={1}
          pt={2}
        >
          <Box pr={1}>
            <LockIcon />
          </Box>
          <Box flexGrow={1}>
            <TextField
              fullWidth
              label="Κωδικός"
              id="password"
              name="password"
              type="password"
              onChange={handleChange}
              value={values.password}
              helperText={
                errors.password && touched.password ? errors.password : ""
              }
              error={errors.password && touched.password ? true : undefined}
            />
            <PasswordStrengthMeter password={values.password} />
          </Box>
        </Box>
        <Box
          display="flex"
          alignContent="stretch"
          alignItems="flex-end"
          pb={1}
          pt={2}
        >
          <Box pr={1}>
            <LockIcon />
          </Box>
          <Box flexGrow={1}>
            <TextField
              fullWidth
              label="Επιβεβαίωση κωδικού"
              id="passwordConfirmation"
              name="passwordConfirmation"
              type="password"
              onChange={handleChange}
              value={values.passwordConfirmation}
              helperText={
                errors.passwordConfirmation && touched.passwordConfirmation
                  ? errors.passwordConfirmation
                  : ""
              }
              error={
                errors.passwordConfirmation && touched.passwordConfirmation
                  ? true
                  : undefined
              }
            />
          </Box>
        </Box>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={props.classes.submit}
          disabled={isSubmitting}
        >
          ΚΑΤΑΧΩΡΗΣΗ ΚΩΔΙΚΟΥ
        </Button>
      </form>
    </div>
  );
};
