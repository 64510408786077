import { authFetch } from '_helpers/authFetch';

export const storeService = {
    getUserStores,
    storeUserStore,
};

async function getUserStores(){
    const requestOptions = {
        method: 'GET',
        credentials: 'include',
    };

    return await authFetch(`${process.env.REACT_APP_MERCURYURL}api/stores/getstores`, requestOptions)
        .then(stores => {
            return stores;
        });
}

async function storeUserStore(store, storeName){

    localStorage.removeItem('currentStore');
    localStorage.setItem('currentStore', JSON.stringify( {storeId : store, storeName : storeName }));

    const requestOptions = {
        method: 'POST',
        body: store,
        headers: {
            'Content-Type': 'application/json'
        }
    };

    return await authFetch(`${process.env.REACT_APP_MERCURYURL}api/salesmen/PostUserDefaultStore`, requestOptions)
        .catch((err) => {
            return true;
        })
        .then(status => {
            if (status.message)
                return status.message;
            else
            {
                return true;
            }
        });
}