import React from "react";
import Grid from "@material-ui/core/Grid";
import { brandTextsService } from "_services/brandtexts.service";
import { Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

export class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      body: "",
    };
  }
  componentDidMount() {
    brandTextsService.getBrandText("PrivacyPolicy").then((brandText) => {
      this.setState({
        title: brandText.title,
        body: brandText.body,
      });
    });
  }
  render() {
    return (
      <Grid item xs={12} sm={6} lg={3} className="static-page card-container">
      <Typography variant="h1">{this.state.title}</Typography>
        <Card>
          <CardContent>
            <div
              className="body"
              dangerouslySetInnerHTML={{ __html: this.state.body }}
            />
          </CardContent>
        </Card>
      </Grid>
    );
  }
}
