import { BehaviorSubject } from 'rxjs';
import parseISO from 'date-fns/parseISO';

import { handleResponse } from '_helpers/handle-response';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const authenticationService = {
    login,
    logout,
    sendActivationVerificationSMS,
    validateSMSCode,
    activateAccount,
    sendPasswordResetVerificationSMS,
    isLogin : isLogin(),
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() { return currentUserSubject.value }
};

function isLogin() {
    let userStr = localStorage.getItem('currentUser');
    if (!userStr)
        return false;
    else
    {
        let userData = JSON.parse(userStr);
        return Date.now() < parseISO(userData.exp);
    }
}

async function login(username, password, rememberme) {

    const requestOptions = {
        method: 'POST',
        credentials: 'include',
        body: 'username=' + username + '&password=' + password + '&client_id=' + process.env.REACT_APP_MERCURYCLIENTID +
            '&applicationId=' + process.env.REACT_APP_MERCURYAPPLICATIONID + "&rememberme=" + rememberme,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    };

    let out = await logout().catch(() => true).then(() => true);
    if (out)
        return await fetch(`${process.env.REACT_APP_MERCURYURL}api/logon`, requestOptions)
            .then(handleResponse)
            .then(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                currentUserSubject.next(user);
                return user;
            });
}

async function logout() {
    const requestOptions = {
        method: 'POST',
        //credentials: 'include',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    };

    return await fetch(`${process.env.REACT_APP_MERCURYURL}api/logout`, requestOptions)
        .catch(() => true)
        .then(() => true);
}

async function sendActivationVerificationSMS(mobileNumber) {
    const requestOptions = {
        method: 'POST',
        body: 'applicationid=' + process.env.REACT_APP_MERCURYAPPLICATIONID + '&phoneNumber=' + mobileNumber,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    };

    return await fetch(`${process.env.REACT_APP_MERCURYURL}api/salesmen/PostSendVerificationSMS`, requestOptions)
        .then(handleResponse)
        .then(smsToken => {
            localStorage.setItem('smsToken', JSON.stringify(smsToken));
            return smsToken;
        });
}

async function activateAccount(smsToken, smsCode, password)
{
    const requestOptions = {
        method: 'POST',
        body: 'tokenString=' + smsToken + '&smsCode=' + smsCode + "&password=" + password + "&client_id=" + process.env.REACT_APP_MERCURYCLIENTID,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    };

    return await fetch(`${process.env.REACT_APP_MERCURYURL}api/salesmen/PostVerifySMS`, requestOptions)
        .then(handleResponse)
        .then(status => {
            if(status === "Success") {
                return true;
            } else {
                Promise.error(status);
            }
        });
   
}


async function validateSMSCode(smsToken, smsCode)
{
    const requestOptions = {
        method: 'POST',
        body: 'tokenString=' + smsToken + '&smsCode=' + smsCode + "&client_id=" + process.env.REACT_APP_MERCURYCLIENTID,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    };

    return await fetch(`${process.env.REACT_APP_MERCURYURL}api/salesmen/PostVerifySMSCode`, requestOptions)
        .then(handleResponse)
        .catch(error => {
            return false;
        })
        .then(user => {
            if(!user.message && user) {
                return true;
            }
            return false;
        });
   
}


async function sendPasswordResetVerificationSMS(mobileNumber, email) {
    const requestOptions = {
        method: 'POST',
        body: 'applicationid=' + process.env.REACT_APP_MERCURYAPPLICATIONID + '&phoneNumber=' + mobileNumber + "&email=" + email,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    };

    return await fetch(`${process.env.REACT_APP_MERCURYURL}api/salesmen/PostPasswordReset`, requestOptions)
        .then(handleResponse)
        .then(smsToken => {
            localStorage.setItem('smsToken', JSON.stringify(smsToken));
            return smsToken;
        });
}

