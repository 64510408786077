import { handleResponse } from "_helpers/handle-response";
import Cookies from "universal-cookie";
import { Notifier } from '@airbrake/browser';

export const authFetch = async (input, init) => {
  const cookies = new Cookies();

  init = init || {};

  // Add xsrfToken to header if POST
  let xsrfToken = {};
  if (init.method && init.method === "POST") {
    xsrfToken = {
      xsrfToken: cookies.get("xsrfToken"),
    };
  }

  init.credentials = "include";
  init.headers = {
    ...init.headers,
    ...xsrfToken,
  };

  return fetch(input, init)
    .catch((error) => {
      
    let airbrake = new Notifier({
        projectId: 278527,
        projectKey: 'bcb610289dee895520655ecadcb24002'
      });
      let userStr = localStorage.getItem("currentUser");
      let user = "anonymous";
      if (userStr) {
        user = JSON.parse(userStr).fullName ?? "";
      }
      airbrake.notify({
        error: error,
        params: {url: input, user: user, location: "authFetch"}
      });
      return Promise.reject(error);
    })
    .then(handleResponse);
};
