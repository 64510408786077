import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import PersonIcon from "@material-ui/icons/Person";
import LockIcon from "@material-ui/icons/Lock";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import { authenticationService } from "_services/authentication.service";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(5),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const Login = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const validationSchema = Yup.object({
    username: Yup.string().required("Το username είναι υποχρεωτικό"),
    password: Yup.string().required("Το password είναι υποχρεωτικό"),
  });

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    isSubmitting,
    status,
  } = useFormik({
    initialValues: {
      username: "",
      password: "",
      rememberme: false,
    },
    validationSchema,
    onSubmit(values, { setStatus, setSubmitting }) {
      setStatus();
      authenticationService
        .login(values.username, values.password, values.rememberme)
        .then(
          (user) => {
            localStorage.removeItem("rememberme");
            //localStorage.setItem("rememberme", JSON.stringify(values.rememberme));
            localStorage.removeItem("cart");
            props.successLogin();
            history.push("/location");
          },
          (error) => {
            var msg = error;
            switch (msg) {
              case "The user name or password is incorrect.":
              case "FailedAuthentication":
                msg =
                  'Λάθος στοιχεία σύνδεσης. Δοκίμασε ξανά ή επέλεξε "Ξέχασα τον κωδικό μου"';
                break;
              case "AccountLockedOut":
                msg =
                  'Ο λογαριασμός σας έχει κλειδωθεί για λόγους ασφαλείας μετά από αποτυχημένες προσπάθειες εισόδου. Επικοινωνήστε με την ομάδα εξυπηρέτησης στο <a class="phone-number" href="tel:00302100108950">210 010 8950</a>';
                break;
              case "AccountInactive":
                msg =
                  'Ο λογαριασμός σας δεν έχει ενεργοποιηθεί. Επικοινωνήστε με την ομάδα εξυπηρέτησης στο <a class="phone-number" href="tel:00302100108950">210 010 8950</a>';
                break;
              case "NoPermisssions":
                msg =
                  'Ο λογαριασμός σας έχει ανεπαρκή δικαιώματα. Επικοινωνήστε με την ομάδα εξυπηρέτησης στο <a class="phone-number" href="tel:00302100108950">210 010 8950</a>';
                break;
              default:
                msg =
                  'Λάθος στοιχεία σύνδεσης. Δοκίμασε ξανά ή επέλεξε "Ξέχασα τον κωδικό μου. Επικοινωνήστε με την ομάδα εξυπηρέτησης στο <a class="phone-number" href="tel:00302100108950">210 010 8950</a> αν το πρόβλημα επαναληφθεί.';
                break;
            }
            setSubmitting(false);
            setStatus(msg);
          }
        );
    },
  });

  return (
    <Grid item xs={12} sm={6} lg={3} className="card-container">
      <Card>
        <CssBaseline />
        <CardContent>
          <Grid container item className={classes.paper}>
            <Typography variant="h2">Είσοδος</Typography>

            {status && (
              <Alert severity="error">
                <span dangerouslySetInnerHTML={{ __html: status }} />
              </Alert>
            )}
            <form onSubmit={handleSubmit} className={classes.form}>
              <Box
                display="flex"
                alignContent="stretch"
                alignItems="flex-end"
                pb={1}
                pt={1}
              >
                <Box pr={1}>
                  <PersonIcon />
                </Box>
                <Box flexGrow={1}>
                  <TextField
                    fullWidth
                    label="Email"
                    id="username"
                    name="username"
                    type="text"
                    onChange={handleChange}
                    value={values.username}
                    helperText={
                      errors.username && touched.username ? errors.username : ""
                    }
                    error={
                      errors.username && touched.username ? "error" : undefined
                    }
                  />
                </Box>
              </Box>
              <Box
                display="flex"
                alignContent="space-between"
                alignItems="flex-end"
                pb={1}
                pt={1}
              >
                <Box pr={1}>
                  <LockIcon />
                </Box>
                <Box flexGrow={1}>
                  <TextField
                    fullWidth
                    label="Κωδικός"
                    id="password"
                    name="password"
                    type="password"
                    onChange={handleChange}
                    value={values.password}
                    helperText={
                      errors.password && touched.password ? errors.password : ""
                    }
                    error={
                      errors.password && touched.password ? "error" : undefined
                    }
                  />
                </Box>
              </Box>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={isSubmitting}
              >
                ΕΙΣΟΔΟΣ
              </Button>

              <Grid container alignContent="space-between" alignItems="center">
                <Grid>
                  <FormControlLabel
                    id="rememberme"
                    name="rememberme"
                    control={<Checkbox value="check" color="primary" />}
                    label="Αποθήκευση στοιχείων"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid>
                  <Link
                    href="/resetpassword"
                    className="small-link"
                    variant="body2"
                  >
                    Ξέχασα τον κωδικό μου
                  </Link>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </CardContent>
      </Card>
      <Box p={{ xs: 2, sm: 3, md: 4 }} textAlign="center">
        <Typography className="call-label">Τηλέφωνο υποστήριξης</Typography>
        <a className="phone-number" href="tel:00302100108950">
          210 010 8950
        </a>
      </Box>
    </Grid>
  );
};
