import React, { Component } from "react";
import { Route, Redirect } from "react-router";
import { Layout } from "./components/Layout";
import { Home } from "./components/Home";
import { Login } from "./components/account/Login";
import { Logout } from "./components/account/Logout";
import ActivateAccount from "./components/account/ActivateAccount";
import ResetPassword from "./components/account/ResetPassword";
import ChooseStore from "./components/salesman/ChooseStore";
import Order from "./components/sales/order";
import Dashboard from "./components/salesman/Dashboard";
import ProductList from "./components/products/ProductList";
import { OrdersHistory } from "./components/salesman/ordersHistory";
import PointsHistory from "./components/salesman/pointsHistory";
import { ProfileEdit } from "./components/salesman/editProfile";
import { Club } from "./components/info/club";
import { CookiesPolicy } from "./components/info/cookiesPolicy";
import { PrivacyPolicy } from "./components/info/privacyPolicy";
import { LegalNotice } from "./components/info/legalNotice";
import OnLineOnly from "./components/info/onLineOnly";

import { Help } from "./components/support/help";

import { authenticationService } from "_services/authentication.service";
import { salesmanService } from "_services/salesman.service";

const PrivateRoute = ({ component: Component, loggedIn, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      loggedIn ? <Component {...props} /> : <Redirect to="/home" />
    }
  />
);
const PrivateRouteOnLineOnly = ({ component: Component, loggedIn, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !navigator.onLine ? <OnLineOnly/> : (
      loggedIn ? <Component {...props} /> : <Redirect to="/home" />)
    }
  />
);
const RouteOnLineOnly = ({ component: Component, loggedIn, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !navigator.onLine ? <OnLineOnly/> :  <Component {...props} /> 
    }
  />
);

export default class App extends Component {
  static displayName = App.name;

  constructor() {
    super();
    this.successLogin = this.successLogin.bind(this);
    this.successLogout = this.successLogout.bind(this);
    this.state = {
      isUserAuthenticated: authenticationService.isLogin,
      hasStore: this.hasStore(),
      initials: salesmanService.getSalesmanInitials,
    };
  }

  successLogout() {
    this.setState({
      isUserAuthenticated: false,
      hasStore: this.hasStore(),
      initials: salesmanService.getSalesmanInitials,
    });
  }

  successLogin() {
    this.setState({
      isUserAuthenticated: true,
      hasStore: this.hasStore(),
      initials: salesmanService.getSalesmanInitials,
    });
  }

  hasStore() {
    let storeId = null;
    let store = localStorage.getItem('currentStore');
    if (store != null)
    {
      storeId = JSON.parse(store).storeId;
    }
    
    return !(storeId === null || storeId === undefined);
  }

  render() {
    return (
      <Layout
        IsLogin={this.state.isUserAuthenticated}
        initials={this.state.initials}
      >
        <Route
          exact
          path="/"
          render={() => {
            return this.state.isUserAuthenticated ? (
              this.hasStore() ? (
                <Redirect to="/order" />
              ) : (
                <ChooseStore open={true} />
              )
            ) : (
              <Redirect to="/home" />
            );
          }}
        />
        <Route path="/home" component={Home} />
        <Route
          path="/login"
          render={(props) => <Login successLogin={this.successLogin} />}
        />
        <RouteOnLineOnly
          path="/activate"
          component={(props) => (
            <ActivateAccount successActivate={this.successLogin} />
          )}
        />
        <RouteOnLineOnly
          path="/resetpassword"
          component={(props) => <ResetPassword successReset={this.successLogin} />}
        />
        <Route path="/logout" 
          render={(props) => <Logout successLogout={this.successLogout} /> }
        />
        <PrivateRoute
          path="/location"
          loggedIn={this.state.isUserAuthenticated}
          component={() => <ChooseStore open={true} />}
        />
        <PrivateRoute
          path="/order"
          loggedIn={this.state.isUserAuthenticated}
          component={Order}
        />
        <PrivateRouteOnLineOnly
          path="/dashboard"
          loggedIn={this.state.isUserAuthenticated}
          component={Dashboard}
        />
        <PrivateRouteOnLineOnly
          path="/ordershistory"
          loggedIn={this.state.isUserAuthenticated}
          component={OrdersHistory}
        />
        <PrivateRouteOnLineOnly
          path="/pointshistory"
          loggedIn={this.state.isUserAuthenticated}
          component={PointsHistory}
        />
        <PrivateRouteOnLineOnly
          path="/support"
          loggedIn={this.state.isUserAuthenticated}
          component={Help}
        />
        <PrivateRoute
          path="/clubinfo"
          loggedIn={this.state.isUserAuthenticated}
          component={Club}
        />
        
        <PrivateRoute
          path="/products"
          loggedIn={this.state.isUserAuthenticated}
          component={ProductList}
        />
        <Route path="/cookies-policy" component={CookiesPolicy} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/legal-notice" component={LegalNotice} />
        <PrivateRouteOnLineOnly
          path="/profile-edit"
          loggedIn={this.state.isUserAuthenticated}
          component={ProfileEdit}
        />
      </Layout>
    );
  }
}
