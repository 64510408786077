import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import { el } from "date-fns/locale";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";

import { salesmanService } from "_services/salesman.service";

const styles = (theme) => ({
  card: {
    maxWidth: "460px",
    margin: "0 auto",
    marginBottom: "10px",
  },
  cardcontent: {
    display: "block",
    "justify-content": "center",
    "align-items": "center",
    "&:last-child": {
      paddingBottom: "16px",
    },
  },
  pointsTitle: {
    fontSize: 12,
    fontWeight: "bold",
  },
  pointsMonths: {
    fontSize: 12,
    color: "#8297A7",
  },
  points: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 100,
    "font-size": "xx-large",
    width: 80,
    height: 80,
  },
  pointsLevel1: {
    backgroundColor: "#8499FE",
  },
  pointsLevel2: {
    backgroundColor: "green",
  },
  pointsLevel3: {
    background:
      "transparent linear-gradient(213deg, #B76FFF 0%, #66B2FD 100%) 0% 0% no-repeat padding-box;",
  },
  pointsLevel4: {
    backgroundColor: "red",
  },
  levelBox: {
    textAlign: "center",
    fontWeight: 100,
  },
});

class PointsHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = { pointsHistoryData: [], loading: true };
    this.populatePointsHistoryData = this.populatePointsHistoryData.bind(this);
    this.renderPointsHistory = this.renderPointsHistory.bind(this);
    this.returnPointsAvatarColor = this.returnPointsAvatarColor.bind(this);
  }

  componentDidMount() {
    this.populatePointsHistoryData();
  }

  async populatePointsHistoryData() {
    try {
      const response = await salesmanService.PointsHistoryData();
      const data = await response;
      this.setState({ pointsHistoryData: data, loading: false });
    } catch (error) {
      this.setState({ error: error });
    }
  }

  returnPointsAvatarColor(level) {
    const { classes } = this.props;
    switch (level) {
      case 1:
        return classes.pointsLevel1;
      case 2:
        return classes.pointsLevel2;
      case 3:
        return classes.pointsLevel3;
      case 4:
        return classes.pointsLevel4;
      default:
        return classes.points;
    }
  }

  renderPointsHistory() {
    const { classes } = this.props;
    return (
      <Grid container item alignItems="center" alignContent="center" xs={12}>
        <Grid item className={classes.card}>
          <Typography variant="h1">Ιστορικό πόντων</Typography>
          <Grid item xs={12}>
            {this.state.pointsHistoryData.map((points, cardIdx) => (
              <Card key={cardIdx} className={classes.card}>
                <CardContent className={classes.cardcontent}>
                  <Box display="flex">
                    <Box display="block">
                      <Typography className={classes.pointsTitle} gutterBottom>
                        {points.periodName}
                      </Typography>
                      <Box
                        display="block"
                        width={200}
                        className={classes.levelBox}
                      >
                        <Typography className={classes.pointsMonths}>
                          {format(parseISO(points.periodStart), "MMM", {
                            locale: el,
                          }) +
                            ".-" +
                            format(parseISO(points.periodEnd), "MMM", {
                              locale: el,
                            }) +
                            "."}
                        </Typography>
                        <Typography className={classes.programLevel}>
                          {points.loyaltyProgramLevelName}
                        </Typography>
                      </Box>
                    </Box>
                    <Box width={80}>
                      <Avatar
                        className={[
                          classes.points,
                          this.returnPointsAvatarColor(
                            points.loyaltyProgramLevel
                          ),
                        ].join(" ")}
                      >
                        {points.totalPoints}
                      </Avatar>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            ))}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  render() {
    if (this.state.error) throw new Error(this.state.error);

    let contents = "";

    if (this.state.loading) {
      contents = <div className="progress-container"><CircularProgress /></div>;
    } else if (
      this.state.pointsHistoryData === undefined ||
      this.state.pointsHistoryData.length === 0
    ) {
      contents = (
        <Alert severity="warning">
          Άρχισε να καταχωρείς τις πωλήσεις σου και θα δεις τους πόντους σου να
          ανεβαίνουν!
        </Alert>
      );
    } else contents = this.renderPointsHistory();

    return <React.Fragment>{contents}</React.Fragment>;
  }
}

export default withRouter(withStyles(styles)(PointsHistory));
