import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MenuIcon from "@material-ui/icons/Menu";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PersonIcon from "@material-ui/icons/Person";

import { ReactComponent as NeutrogenaLogo } from "../images/svg/neutrogena.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const DefaultMenu = (props) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [isLogin, setIsLogin] = React.useState(props.IsLogin);
  const [initials, setInitials] = React.useState(props.initials);

  React.useEffect(() => {
    setIsLogin(props.IsLogin);
    setInitials(props.initials);
  }, [props.IsLogin, props.initials]);

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorEl2(null);
  };

  const toggleAccountMenu = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const StyledMenu = withStyles({
    paper: {
      border: "1px solid #d3d4d5",
    }
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ));
  const StyledMenuItem = withStyles((theme) => ({
    root: {
      
    },
  }))(MenuItem);

  return (
    <AppBar
      position="static"
      style={{ background: "transparent", boxShadow: "none" }}
    >
      <Toolbar>
        <Link to="/" className={classes.title} title="Neutrogena Bonus Club Home">
          <NeutrogenaLogo />
        </Link>
        {isLogin && (
          <div>
            <IconButton
              aria-controls="account-menu"
              aria-haspopup="true"
              onClick={toggleAccountMenu}
            >
              <div className="initials">{initials}</div>
            </IconButton>
            <StyledMenu
              id="account-menu"
              anchorEl={anchorEl2}
              keepMounted
              open={Boolean(anchorEl2)}
              onClose={handleClose}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <StyledMenuItem
                onClick={handleClose}
                component={Link}
                to="/profile-edit"
              >
                <ListItemIcon>
                  <PersonIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Διαχείριση προφίλ" />
              </StyledMenuItem>

              <StyledMenuItem
                onClick={handleClose}
                component={Link}
                to="/logout"
              >
                <ListItemIcon>
                  <ExitToAppIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Έξοδος" />
              </StyledMenuItem>
            </StyledMenu>
            <IconButton
              aria-controls="app-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="app-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <MenuItem onClick={handleClose} component={Link} to="/">
                Αρχική
              </MenuItem>
              <MenuItem onClick={handleClose} component={Link} to="/dashboard">
                Οι επιδόσεις μου
              </MenuItem>
              <MenuItem onClick={handleClose} component={Link} to="/ordershistory">
                Πωλήσεις περιόδου
              </MenuItem>
              <MenuItem onClick={handleClose} component={Link} to="/pointshistory">
                Ιστορικό πόντων
                </MenuItem>
              
                {/* <MenuItem onClick={handleClose} component={Link} to="/ranking">Κατάταξη</MenuItem> */}
              
                <MenuItem onClick={handleClose} component={Link} to="/products">Προϊόντα</MenuItem>
                <MenuItem onClick={handleClose} component={Link} to="/clubinfo">Το πρόγραμμα</MenuItem>
              
                <MenuItem onClick={handleClose} component={Link} to="/support">Βοήθεια</MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default DefaultMenu;
