import React from 'react';
import Popover from '@material-ui/core/Popover';
import Alert from '@material-ui/lab/Alert';

export default function NetworkStatusMessage() {
  const [open, setΟpen] = React.useState(false);

  window.addEventListener('offline', () => {
    setΟpen(true);
  });
  window.addEventListener('online', () => {
    setΟpen(false);
  });

  const handleClose = () => {
    setΟpen(false);
  };


  return (
    <Popover 
        open={open}
        onClose={handleClose}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
    >   
        <Alert severity="warning">Χάθηκε η πρόσβαση στο Internet<br/>Η εφαρμογή συνεχίζει να λειτουργεί!</Alert>
    </Popover>
  );
}
