import React, { useState } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { withRouter } from "react-router-dom";
import Slider from "react-slick";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import CloseIcon from "@material-ui/icons/Close";
import { CardActions } from "@material-ui/core";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import { InputAdornment } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import elLocale from "date-fns/locale/el";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import Icon from '@material-ui/core/Icon';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import CircularProgress from '@material-ui/core/CircularProgress';

import { ReactComponent as PharmacyIcon } from '../../images/svg/pharmacy.svg';
import { ReactComponent as CalendarIcon } from '../../images/svg/calendar.svg';
import ScrollMenu from 'react-horizontal-scrolling-menu';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { salesService } from "_services/sales.service";
import { storeService } from '_services/store.service';

import ChooseStore from "components/salesman/ChooseStore";

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  counter: {
    "text-align": "center",
  },
  productCard: {
    padding: "10px",
    display: "block",
    width: 277,
    height: 384,
  },
  productCardContent: {
    "text-align": "center",
    "& img": {
      margin: "0 auto",
    },
  },
  quantityButtons: {
    display: "inline-block",
  },
});


// One item component
// selected prop will be passed
const MenuItem = ({text, selected}) => {
  return <div
    className={`category ${selected ? 'selected' : ''}`}
    >{text}</div>;
};
 
 
const Arrow = ({ text, className }) => {
  return (
    <div
      className={className}
    ><span>{text}</span></div>
  );
};
 
 
const ArrowLeft = Arrow({ text: '<', className: 'arrow-prev' });
const ArrowRight = Arrow({ text: '>', className: 'arrow-next' });
 

const Cart = (props) => {
  if (!props.cart || props.cart.length === 0) return null;

  let totalPoints = Object.values(props.cart).reduce(
    (accumulator, currentValue) => {
      var secondaPoints =
        (currentValue.loyaltyPoints ?? 0) * (currentValue.quantity ?? 0);
      return accumulator + secondaPoints;
    },
    0
  );

  return (
    <React.Fragment>
      <Typography variant="h2">Έχεις επιλέξει:</Typography>
      <List className="order-list">
        {props.cart.map((item, index) => (
          <React.Fragment key={index}>
            <ListItem className="order-item" alignItems="center" key={index}>
              <ListItemAvatar className="product-thumb-container">
                <Avatar
                  className="product-thumb"
                  src={item.image + "?w=40&h=40&mode=max"}
                  alt={item.name}
                />
              </ListItemAvatar>
              <ListItemText>
                <div className="qty">{("0" + item.quantity).slice(-2)}</div>
                <div className="title">{item.name}</div>
              </ListItemText>
              <ListItemSecondaryAction >
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => props.onProductRemove(index)}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </React.Fragment>
        ))}
      </List>
      <Typography variant="h3">ΠΟΝΤΟΙ ΠΩΛΗΣΗΣ</Typography>
      <Typography variant="body1" className="order-points">
        {totalPoints}
      </Typography>
    </React.Fragment>
  );
};

const OrderProperties = (props) => {
  let minDate = new Date(props.selectedDate);
  minDate.setDate(minDate.getDate() - 10);

  return (
      <List disablePadding>
        <Divider />
        <ListItem disableGutters>
          <ListItemAvatar>
          <Icon>
            <PharmacyIcon />
          </Icon>
          </ListItemAvatar>
          <ListItemText>
            <Box display="flex" alignItems="center">
              <div className="label">ΦΑΡΜΑΚΕΙΟ: </div>

              <div className="value">{props.storeName}</div>
              <IconButton
                edge="end"
                aria-label="change"
                onClick={props.changeStore}
                className="edit-button"
                size="small"
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Box>
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem disableGutters>
          <ListItemAvatar>
          <Icon>
            <CalendarIcon />
          </Icon>
          </ListItemAvatar>
          <ListItemText>
            <Box display="flex" alignItems="center">
              <div className="label">ΗΜΕΡΟΜΗΝΙΑ: </div>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={elLocale}>
                <DateTimePicker
                  id="order_datetime"
                  disableFuture
                  allowKeyboardControl={false}
                  fullWidth={true}
                  minDate={minDate}
                  inputVariant="standard"
                  ampm={false}
                  format="dd/MM/yyyy hh:mm"
                  className="value"
                  value={props.selectedDate}
                  onChange={props.handleDateChange}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton className="edit-button" size="small">
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </MuiPickersUtilsProvider>
            </Box>
          </ListItemText>
        </ListItem>{" "}
        <Divider />
      </List>
    
  );
};

const ProductQuantity = (props) => {

  const quantityChange = (changeValue) => {
    props.onQuantityChange(props.productId, props.value + changeValue);
  };

  return (
    <div className="qty-widget">
      <div className="btn" onClick={() => quantityChange(-1)}>
        <RemoveIcon fontSize="inherit" />
      </div>
      <div className="qty">{props.value}</div>
      <div className="btn" onClick={() => quantityChange(1)}>
        <AddIcon fontSize="inherit" />
      </div>
    </div>
  );
};

const ProductCategories = (props) => {
  var settings = {
    arrows: true,
    dots: false,
    infinite: false,
    speed: 500,
    swipe: true,
    slidesToShow: 6,
    slidesToScroll: 6,
    centerMode: false,
    variableWidth: true,
    focusOnSelect: false,
    responsive: [
      {
        breakpoint: 1260,
        settings: {
          centerMode: false,
          slidesToShow: 6,
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          centerMode: false,
          slidesToShow: 6,
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: 600,
        settings: {
          centerMode: false,
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 480,
        settings: {
          centerMode: false,
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  var categories = props.productCategories.map((c) => ({
    categoryId: c.categoryId,
    categoryName: c.name,
  }));
  categories.unshift({ categoryId: 0, categoryName: "Best Sellers" });
  return (
    <Slider className="product-categories" {...settings}>
      {categories.map((category, index) => (
        <Typography
          key={index}
          onClick={() => props.handleCategoryChange(index)}
          className="category"
        >
          {category.categoryName}
        </Typography>
      ))}
    </Slider>
  );
};

const SubmitDialog = (props) => {

  return (
    <Dialog open={props.open} aria-labelledby="form-dialog-title" className="order-confirmation">
      <DialogTitle disableTypography id="form-dialog-title">
        <IconButton aria-label="close"  style={{ color: 'white', position: 'absolute', right: '10px', top: '10px' }} onClick={props.handleCloseSubmitDialog}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="message">
        <CheckRoundedIcon />
        {!props.data.Offline ? 
          (<React.Fragment>
            <p className="message-1">
              {!props.isEdit ? 'Η καταχώρηση έγινε επιτυχώς!' : 'Οι αλλαγές σας καταχωρήθηκαν!'}
            </p>
            <p className="message-2">Ο νέος συνολικός αριθμός πόντων είναι</p>
            <p className="message-points">{props.data.currentPoints}</p>
            <p className="message-3">Απομένουν ακόμα {props.data.nextLevelRemainingPoints} πόντοι για να γίνεις {props.data.nextLevel}</p>
          </React.Fragment>) : (
          <p className="message-1">
            Πραγματοποιήθηκε προσωρινή αποθήκευση. Η καταχώρηση θα ολοκληρωθεί όταν συνδεθείτε ξανά στο δίκτυο!
            </p>
          )
        }
      </DialogContent>
    </Dialog>
  );
};

const EditDialog = (props) => {

  const handleClose = (action) => {
    props.handleCloseEditDialog(action);
  };
  return(
      <Dialog open={props.open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{"Επεξεργασία παραγγελίας"}</DialogTitle>
          <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Θέλετε να επεξεργαστείτε την παραγγελία με κωδικό #{props.transactionId}, που καταχωρήθηκε {format(props.transactionDate, "dd/MM/yyyy hh:mm")};
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(1)}>
          ΕΠΕΞΕΡΓΑΣΙΑ
        </Button>
        <Button onClick={() => handleClose(0)} autoFocus>
          ΑΚΥΡΩΣΗ
        </Button>
      </DialogActions>
    </Dialog>
  );
}

class Order extends React.Component {
  constructor(props) {
    super(props);
    let cart = [];
    let allowSubmit = false;
    let selectedDate = new Date();
    let store = JSON.parse(localStorage.getItem("currentStore"));
    // Sync selected store 
    if (store !== null) {
      storeService.storeUserStore(store.storeId, store.storeName);
    }

    if (!this.props.location.state || !this.props.location.state.transactionId || this.props.location.state.transactionId === 0) {
      let cartStr = localStorage.getItem("cart");
      if (cartStr) {
        cart = JSON.parse(cartStr);
        allowSubmit = true;
      }
    }

    this.state = {
      productCategories: [],
      productCategory: 0,
      products: [],
      productQuantityArray: [],
      loading: true,
      cart: cart,
      errorMsg: "",
      productIndex: 1,
      allowSubmit: allowSubmit,
      store: store,
      openStoreDialog: (store === null),
      selectedDate: selectedDate,
      showSubmitDialog: false,
      showEditDialog : false,
      submitDialogData: [],
      error: undefined,
      editTranscactionId : 0,
      selectedCategory: 0,
      productCategoriesMenu: null
    };

    this.addProduct = this.addProduct.bind(this);
    this.saveOrder = this.saveOrder.bind(this);
    this.changeProductQuantity = this.changeProductQuantity.bind(this);
    this.getProductQuantity = this.getProductQuantity.bind(this);
    this.removeProduct = this.removeProduct.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.handleCloseSubmitDialog = this.handleCloseSubmitDialog.bind(this);
    this.handleCloseEditDialog = this.handleCloseEditDialog.bind(this);
  }

  onSelectCategory = key => {
    if (this.state.selectedCategory !== key) {
      this.populateProductsBySelectedCategory(key);
      this.setState({ selectedCategory: key });
    }
  }
  componentDidMount() {
    if (!this.state.openStoreDialog) 
    {
      if (this.populateProductCategories()) {
        this.populateProductsBySelectedCategory(this.state.selectedCategory);
      }

      if (this.props.location.state && this.props.location.state.transactionId && this.props.location.state.transactionId !== 0) {
        let transcactionId = this.props.location.state.transactionId;
        salesService.getOrder(transcactionId)
        .then(order => {
          let selectedDate = parseISO(order.transactionHeader.transactionDate);
          let store = { storeId : order.transactionHeader.storeId, storeName : order.transactionHeader.storeName };
          let cart = order.transactionDetails.map(item => ({
            transactionItemId : item.transactionItemId,
            productId : item.productId,
            name : item.productName,
            image : item.photoUrl,
            loyaltyPoints : item.loyaltyPoints,
            quantity : item.quantity
          }));
          localStorage.removeItem("cart");
          localStorage.setItem("cart", JSON.stringify(cart));
          this.setState({
            cart: cart,
            errorMsg: "",
            productIndex: 1,
            allowSubmit: true,
            store: store,
            openStoreDialog: false,
            selectedDate: selectedDate,
            showSubmitDialog: false,
            showEditDialog : true,
            submitDialogData: [],
            error: undefined,
            editTranscactionId : transcactionId
          });
          
      });
      }
    }
  }

  getProductbyId(productId) {
    for (let i = 0; i < this.state.products.length; i++) {
      if (this.state.products[i].productId === productId) {
        return i;
      }
    }
    return -1;
  }

  getProductQuantity(productId) {
    let productIndex = this.getProductbyId(productId);
    return productIndex > -1
      ? this.state.productQuantityArray[productIndex]
      : 1;
  }

  changeProductQuantity(productId, quantity) {
    if (quantity > 0 && quantity < 50) {
      let productIndex = this.getProductbyId(productId);
      if (productIndex > -1) {
        let productQuantityArray = this.state.productQuantityArray;
        productQuantityArray[productIndex] = quantity;
        this.setState({ productQuantityArray: productQuantityArray });
      }
    }
  }

  addProduct(index) {
    let product = this.state.products[index];
    let quantity = this.state.productQuantityArray[index];
    let cart = this.state.cart;
    let cartWithoutThisProduct = cart.filter(function (item) {
      return item.productId !== product.productId;
    });
    cartWithoutThisProduct.unshift({
      productId: product.productId,
      name: product.name,
      image: product.photoUrl,
      loyaltyPoints: product.loyaltyPoints,
      quantity: quantity,
    });

    localStorage.removeItem("cart");
    localStorage.setItem("cart", JSON.stringify(cartWithoutThisProduct));
    this.setState({ cart: cartWithoutThisProduct, allowSubmit: true });
  }

  removeProduct(index) {
    let allowSubmit = true;
    let cart = this.state.cart;

    if (cart.length > 1) {
      cart.splice(index, 1);

      localStorage.removeItem("cart");
      localStorage.setItem("cart", JSON.stringify(cart));
    } else {
      cart = [];
      allowSubmit = false;
      localStorage.removeItem("cart");
    }

    this.setState({ cart: cart, allowSubmit: allowSubmit });
  }

  handleCategoryChange(index) {
    let categoryId = 0;
    if (index > 0) {
      let category = this.state.productCategories[index - 1];
      categoryId = category.productCategoryId;
    }
    if (this.state.productCategory !== categoryId) {
      this.populateProductsBySelectedCategory(categoryId);
    }
  }

  handleDateChange(value) {
    //var newDateTime = Date.parse(value);
    this.setState({ selectedDate: value });
  }

  saveOrder() {    
    this.setState( {allowSubmit : false });
    let order = {
      storeId : this.state.store.storeId,
      transactionDateTime: this.state.selectedDate,
      transactionType: 1, // TODO: select transactionType
      documentTypeId: 1, // TODO: select documentType
    };
    let orderitems = [];
    if (this.state.editTranscactionId === 0) {
      orderitems = this.state.cart.map((i) => ({
        productId: i.productId,
        quantity: i.quantity,
      }));
    } else {
      orderitems = this.state.cart.map((i) => ({
        TransactionItemId : i.transactionItemId,
        productId: i.productId,
        quantity: i.quantity,
      }));
      order["TransactionId"] = this.state.editTranscactionId;
    }
    order["items"] = orderitems;

    if (this.state.editTranscactionId === 0) {
      salesService.saveOrder(order)
      .then((data) => {
        this.setState({ showSubmitDialog: true, submitDialogData: data });
      });
    } else {
      salesService.updateOrder(order)
      .then((data) => {
        this.setState({ showSubmitDialog: true, submitDialogData: data });
      });

    }
  }

  handleCloseSubmitDialog() {
    localStorage.removeItem("cart");
    let state = this.props.history.location.state;
    if(state) {
      delete state.transactionId;
      this.props.history.replace({ ...this.props.history.location, state });
    }
    
    this.setState({
      productCategory: 0,
      products: [],
      productQuantityArray: [],
      loading: true,
      cart: [],
      errorMsg: "",
      productIndex: 1,
      allowSubmit: false,
      store: JSON.parse(localStorage.getItem("currentStore")),
      openStoreDialog: false,
      selectedDate: new Date(),
      showSubmitDialog: false,
      showEditDialog : false,
      submitDialogData: [],
      editTranscactionId : 0
    });
    
    this.populateProductsBySelectedCategory(0);
    document.getElementsByClassName("category")[0].focus();
  }

  handleCloseEditDialog(action) {
    
    if (action === 0) {
      this.handleCloseSubmitDialog();
    } else
      this.setState({ showEditDialog : false })

  }

  renderProductCarousel(products) {
    const { classes } = this.props;
    return products.map((product, index) => (
      <Card className="product" key={product.productId}>
        <CardContent className={classes.productCardContent}>
          <div className="product-image-container">
            <img
              src={product.photoUrl + "?w=160&h=160&mode=max"}
              alt={product.name}
            />
          </div>
          <ProductQuantity
            onQuantityChange={this.changeProductQuantity}
            productId={product.productId}
            value={this.state.productQuantityArray[index]}
            classes={classes}
          />
          <h2>{product.name}</h2>
        </CardContent>
        <CardActions>
          <Button
            className="btnAddToCart"
            onClick={() => this.addProduct(index)}
          >
            <AddIcon fontSize="default" color="inherit" />
          </Button>
        </CardActions>
      </Card>
    ));
  }

  render() {
    if (this.state.error) throw new Error(this.state.error);

    const { classes } = this.props;
    let loading = this.state.loading ? (
      <div className="progress-container">
        <CircularProgress />
      </div>
    ) : (
      ""
    );
    let contents = !this.state.loading
      ? this.renderProductCarousel(this.state.products)
      : "";

    var settings = {
      arrows: true,
      dots: false,
      infinite: false,
      swipe: true,
      centerPadding: "40px",
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1260,
          settings: {
            centerMode: false,
            slidesToShow: 4,
            slidesToScroll: 4,
            arrows: true
          },
        },
        {
          breakpoint: 1024,
          settings: {
            centerMode: false,
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: true
          },
        },
        {
          breakpoint: 800,
          settings: {
            centerMode: false,
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            centerMode: true,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],

      afterChange: (index) => {
        this.setState({ productIndex: index + 1 });
      },
    };
  


    let productCategoriesMenu = !this.state.loading
    ? this.state.productCategoriesMenu
    : [];
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Typography variant="h1">
            {(this.state.editTranscactionId && this.state.editTranscactionId > 0) ? 'Επεξεργασία παραγγελίας #' + this.state.editTranscactionId : 'Καταχώρησε τις πωλήσεις σου'}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={9} lg={9} className="product-categories">
        <ScrollMenu
          data={ productCategoriesMenu }
          arrowLeft={ArrowLeft}
          arrowRight={ArrowRight}
          selected={this.state.selectedCategory}
          onSelect={this.onSelectCategory}
          alignCenter={false}
          hideSingleArrow={true}
        />
        </Grid>
        {loading}
        <Grid item xs={12} sm={12} lg={12} className="counter-container">
          <div className={classes.counter}>
            {!this.state.loading && (
              <div>
                {this.state.productIndex}&nbsp;/&nbsp;
                {this.state.products.length}
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={12} sm={12} lg={12} className="products-container">
          <div>
            <Slider ref={slider => (this.slider = slider)}  className="products-list" className="products" {...settings}>
              {contents}
            </Slider>
          </div>
        </Grid>
        <Grid item xs={12} sm={9} lg={6} className="order-items">
          <Cart cart={this.state.cart} onProductRemove={this.removeProduct} />
        </Grid>
        <Grid item xs={12} sm={9} lg={6} className="order-properties">
          <OrderProperties
            storeName={this.state.store !== null ? this.state.store.storeName : ""}
            changeStore={() => this.setState({ openStoreDialog: true })}
            selectedDate={this.state.selectedDate}
            handleDateChange={this.handleDateChange}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!this.state.allowSubmit}
            onClick={this.saveOrder}
          >
            {(this.state.editTranscactionId && this.state.editTranscactionId > 0) ? "ΕΝΗΜΕΡΩΣΗ" : "ΚΑΤΑΧΩΡΗΣΗ"}
          </Button>
        </Grid>
        <ChooseStore
          open={this.state.openStoreDialog}
          closeDialog={() => {
              this.setState({
                openStoreDialog: false,
                store: JSON.parse(localStorage.getItem("currentStore"))
              });
              if (this.populateProductCategories()) {
                this.populateProductsBySelectedCategory(this.state.productCategory);
              }
            }
          }
        />
        <SubmitDialog
          open={this.state.showSubmitDialog}
          handleCloseSubmitDialog={this.handleCloseSubmitDialog}
          classes={classes}
          data={this.state.submitDialogData}
          isEdit={(this.state.editTranscactionId && this.state.editTranscactionId > 0)}
        />
        <EditDialog open={this.state.showEditDialog} transactionId={this.state.editTranscactionId} 
          transactionDate={this.state.selectedDate} handleCloseEditDialog={this.handleCloseEditDialog}/>
      </React.Fragment>
    );
  }

  async populateProductsBySelectedCategory(productCategoryId) {
    if (productCategoryId == 0) return this.populateBestSellerProductData();
    else return this.populateProductsByCategory(productCategoryId);
  }

  async populateProductCategories() {
    try {
      const response = await salesService.getProductCategories(
        `${process.env.REACT_APP_MERCURYPRODUCTCATEGORYROOTID}`
      );
      const data = await response;

      var productCategories = data.map((c) => ({
        categoryId: c.productCategoryId,
        categoryName: c.name,
      }));
      productCategories.unshift({ categoryId: 0, categoryName: "Best Sellers" });
      var menu =  productCategories.map(el => {
        return <MenuItem text={el.categoryName} key={el.categoryId} selected={0} />;
      });
      this.setState({ productCategoriesMenu: menu, selectedCategory: 0 });

      this.slider.slickGoTo(0);

      return true;
    } catch (error) {
      this.setState({ error: error });
      return false;
    }
  }

  async populateProductsByCategory(categoryId) {
    let data = [];
    try {
      const response = await salesService.getProductsByCategory(categoryId);
      data = await response;
    } catch (error) {
      //this.setState({ error: error });
    }
    let productQuantityArray = new Array(data.length).fill(1);
    this.setState({
      products: data,
      loading: false,
      productIndex: 1,
      productQuantityArray: productQuantityArray,
      productCategory: categoryId,
    });

    this.slider.slickGoTo(0);
  }

  async populateBestSellerProductData() {
    let data = [];
    try {
      const response = await salesService.getBestSellerProductsByStore();
      data = await response;
    } catch (error) {
      //this.setState({ error: error });
    }
    let productQuantityArray = new Array(data.length).fill(1);
    this.setState({
      products: data,
      loading: false,
      productIndex: 1,
      productQuantityArray: productQuantityArray,
      productCategory: 0,
    });
      this.slider.slickGoTo(0);
  }
}

export default withRouter(withStyles(styles)(Order));
