import React from "react";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import { supportService } from "_services/support.service";
import { Typography } from "@material-ui/core";

const ThankYouDialog = (props) => {
  return (
    <Dialog
      open={props.open}
      aria-labelledby="form-dialog-title"
      className="order-confirmation"
    >
      <DialogTitle disableTypography id="form-dialog-title">
        <IconButton
          aria-label="close"
          style={{
            color: "white",
            position: "absolute",
            right: "10px",
            top: "10px",
          }}
          onClick={props.handleCloseSubmitDialog}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="message">
        <CheckRoundedIcon />
        <p className="message-1">Το αίτημά σας έχει καταγραφεί!</p>
      </DialogContent>
    </Dialog>
  );
};

export class Help extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allowSubmit: false,
      ticketText: "",
      showThankYouMessage: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.submitTicket = this.submitTicket.bind(this);
    this.handleCloseSubmitDialog = this.handleCloseSubmitDialog.bind(this);
  }

  handleCloseSubmitDialog() {
    this.setState({
      showThankYouMessage: false,
    });
  }

  handleChange(event) {
    this.setState({
      ticketText: event.target.value,
      allowSubmit: event.target.value.length > 10,
    });
  }
  submitTicket(event) {
    supportService.submitTicket(this.state.ticketText).then(
      (status) => {
        this.setState({ ticketText: "", showThankYouMessage: true });
      },
      (error) => {
        var msg = error;
        alert(msg);
      }
    );
  }
  render() {
    return (
      <Grid item xs={12} sm={8} lg={6} className="support card-container">
        <Typography variant="h1">Χρειάζεσαι βοήθεια;</Typography>
        <Card>
          <CardContent>
            <Typography variant="body1" className="intro-text">
              Μπορείς να μας στείλεις μήνυμα με αυτή τη φόρμα και θα λάβεις την
              απάντηση στο email σου. Αν πρόκειται για άμεση ανάγκη μπορείς να
              επικοινωνήσεις με το support στο παρακάτω νούμερο.<br/><br/>
              θα χρησιμοποιήσουμε τα δεδομένα σου σύμφωνα με την <a href="/privacy-policy">πολιτική απορρήτου</a> για 
              να απαντήσουμε στο αίτημά σου. Σε παρακαλούμε μην καταγράψεις ευαίσθητα προσωπικά δεδομένα. 
            </Typography>
            <TextareaAutosize
              aria-label="Το αίτημά σου"
              placeholder="Το αίτημά σου"
              rowsMin={3}
              rowsMax={6}
              className="textarea"
              onChange={this.handleChange}
              value={this.state.ticketText}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={!this.state.allowSubmit}
              onClick={this.submitTicket}
            >
              ΑΠΟΣΤΟΛΗ
            </Button>
          </CardContent>
        </Card>
        <Typography className="call-label">ή κάλεσε στο</Typography>
        <a className="phone-number" href="tel:00302100108950">
          210 010 8950
        </a>
        <Typography className="support-hours">Ωράριο λειτουργίας<br/>
        Δευτέρα έως Παρασκευή: 09:00 - 17:00</Typography>

        <ThankYouDialog
          open={this.state.showThankYouMessage}
          handleCloseSubmitDialog={this.handleCloseSubmitDialog}
        />
      </Grid>
    );
  }
}
