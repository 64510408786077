import { authFetch } from "_helpers/authFetch";

export const supportService = {
  submitTicket,
};

async function submitTicket(ticketText) {
  // const formData = new FormData();
  // formData.append("ReportedBy", 0); // loads automatically by session
  // formData.append("StoreId", localStorage.getItem("currentStore").storeId);
  // formData.append("Title", "Αίτημα υποστήριξης από το neutrogena bonus club");
  // formData.append("Description", ticketText);
  // formData.append("SupportTicketTypeId", 1);
  const requestOptions = {
    method: "POST",
    credentials: 'include',
    body: JSON.stringify({
      StoreId: localStorage.getItem("currentStore").storeId,
      Title:  "Αίτημα υποστήριξης από το neutrogena bonus club",
      Description: ticketText,
      SupportTicketTypeId: 1
    }),
    headers: {
        'Content-Type': 'application/json'
    }
  };

  return await authFetch(
    `${process.env.REACT_APP_MERCURYURL}api/Support/PostSupportTicket`,
    requestOptions
  )
    .then((status) => {
      return true;
    })
    .catch((err) => {
      return err;
    });
}
