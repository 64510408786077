import 'react-app-polyfill/ie11';
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, useLocation } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import WebFont from "webfontloader";
import App from "./App";
import theme from "components/theme";
import Cookies from 'universal-cookie';
import * as serviceWorker from "./serviceWorker";


WebFont.load({
  // add Google Fonts as "custom" | workaround required
  custom: {
    families: ["Maternial+Icons", "Roboto:300,400,500,700"],
    urls: [
      // for each Google Fonts add url + options you want
      // here add font-display option
      "https://fonts.googleapis.com/css?family=Material+Icons&display=swap",
      "https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap&subset=latin,greek",
    ],
  },
});

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <ThemeProvider theme={theme}>
      <ScrollToTop />
      <App />
    </ThemeProvider> 
  </BrowserRouter>,
  rootElement
);

serviceWorker.register();
// If page reloads sent xsrfToken to service worker
if (navigator.serviceWorker) {
  const cookies = new Cookies();
  let workerxsrfToken = {xsrfToken : cookies.get('xsrfToken')};
  navigator.serviceWorker.ready.then( registration => {
      registration.active.postMessage("update:" + JSON.stringify(workerxsrfToken));
  });
}