import React from 'react';
import { useHistory } from "react-router-dom";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

export default function OnLineOnly() {
  const history = useHistory();

  const handleClose = () => {
    history.push("/");
  };

  return (
        <React.Fragment>
            <Dialog
                open={true}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Η εφαρμογή είναι εκτός δικτύου</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Η λειτουργία αυτή δεν είναι διαθέσιμο όταν δεν υπάρχει σύνδεση με το Internet.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>OK</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
  );
}
