import { authFetch } from "_helpers/authFetch";

export const salesService = {
  getProductCategories,
  getProductsByCategory,
  getBestSellerProductsByStore,
  saveOrder,
  deleteOrder,
  getOrder,
  updateOrder,
};

async function getBestSellerProductsByStore() {
  let storeId = null;
  let store = localStorage.getItem("currentStore");
  if (store != null) {
    storeId = JSON.parse(store).storeId;
  }
  const requestOptions = {
    method: "GET",
  };

  return await authFetch(
    `${process.env.REACT_APP_MERCURYURL}api/products/GetBestSellerProductsByStore?storeId=${storeId}&fromdate`,
    requestOptions
  ).then((products) => {
    return products;
  });
}

async function getProductCategories(rootCategoryId) {
  const requestOptions = {
    method: "GET",
  };

  return await authFetch(
    `${process.env.REACT_APP_MERCURYURL}api/products/getproductcategories?rootCategoryId=${rootCategoryId}`,
    requestOptions
  ).then((productCategories) => {
    return productCategories;
  });
}

async function getProductsByCategory(categoryId) {
  const requestOptions = {
    method: "GET",
  };

  return await authFetch(
    `${process.env.REACT_APP_MERCURYURL}api/products/GetAllProductsByCategory?productCategory=${categoryId}`,
    requestOptions
  ).then((productCategories) => {
    return productCategories;
  });
}

async function saveOrder(order) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(order),
    headers: {
      "Content-Type": "application/json",
    },
  };

  return await authFetch(
    `${process.env.REACT_APP_MERCURYURL}api/Transactions/PostSellerTransaction`,
    requestOptions
  )
    /*.catch((error) => {
      if (error.message === "Failed to fetch") {
        localStorage.removeItem("cart");
        return {
          message: "Offline",
        };
      }
    })*/
    .then((data) => {
      if (data.message) {
        return data.message;
      } else {
        localStorage.removeItem("cart");
        return data;
      }
    });
}

async function updateOrder(order) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(order),
    headers: {
      "Content-Type": "application/json",
    },
  };

  return await authFetch(
    `${process.env.REACT_APP_MERCURYURL}api/Transactions/PostSimpleTransactionUpdate`,
    requestOptions
  ).then((data) => {
    if (data.message) return data.message;
    else {
      localStorage.removeItem("cart");
      return data;
    }
  });
}

async function deleteOrder(id) {
  const requestOptions = {
    method: "POST",
    body: id,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return await authFetch(
    `${process.env.REACT_APP_MERCURYURL}api/Transactions/PostTransactionDelete`,
    requestOptions
  ).then((data) => {
    if (data.message) return data.message;
    else {
      return true;
    }
  });
}

async function getOrder(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return await authFetch(
    `${process.env.REACT_APP_MERCURYURL}api/Transactions/GetTransactionDetails?id=` +
      id,
    requestOptions
  ).then((data) => {
    if (data.message) return data.message;
    else {
      return data;
    }
  });
}
