import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { StylesProvider } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import DefaultMenu from "./DefaultMenu";
import ErrorHandling from "_helpers/errorHandling";
import "custom.scss";
import { Link } from "react-router-dom";
import NetworkStatusMessage from 'components/info/network.status';
import BackgroundSyncInfo from 'components/sales/backgroundSyncInfo';

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
      <StylesProvider injectFirst>
        <Grid container direction="column" justify="center" alignItems="center">
          <NetworkStatusMessage/>
          <BackgroundSyncInfo/>
          <DefaultMenu
            IsLogin={this.props.IsLogin}
            initials={this.props.initials}
          />
          <Box width="100%" minHeight={"500px"}>
            <ErrorHandling>{this.props.children}</ErrorHandling>
          </Box>
          <Grid
            item
            xs={12}
            className="footer"
            style={{ marginTop: 100 }}
          >
            <div className="footer-links">
              <Link to="/privacy-policy">Πολιτική Απορρήτου</Link> 
              <Link to="/cookies-policy">Πολιτική Cookies</Link> 
              <Link to="/legal-notice">Νομικό Σημείωμα</Link> 
            </div>
            <Grid item xs={12} sm={9} lg={9} style={{ margin: "0 auto" }}>
              <Box padding={"5px"}>
                <hr
                  style={{
                    borderStyle: "none",
                    borderBottom: "1px solid #8297A7",
                  }}
                />
                <Typography variant="caption" display="block" gutterBottom>
                  Johnson &amp; Johnson Ελλάς Καταναλωτικά Προϊόντα Α.Ε.Ε. 2020
                  <br />
                  Ο παρών διαδικτυακός τόπος δημοσιεύεται από το Τμήμα
                  Καταναλωτικών προϊόντων της Johnson &amp; Johnson Ελλάς
                  Καταναλωτικά Προϊόντα Α.Ε.Ε., η οποία είναι εξ ολοκλήρου
                  υπεύθυνη για το περιεχόμενό του. Ο παρών διαδικτυακός τόπος
                  προορίζεται για χρήση επισκεπτών από την Ελλάδα.
                  <br />
                  Τελευταία ενημέρωση Ιούνιος 2020.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        
      </StylesProvider>
    );
  }
}
