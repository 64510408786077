import React from "react";
import Alert from "@material-ui/lab/Alert";
import { Typography } from "@material-ui/core";
import { Notifier } from '@airbrake/browser';

export default class ErrorHandling extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, errorInfo);
    let airbrake = new Notifier({
      projectId: 278527,
      projectKey: 'bcb610289dee895520655ecadcb24002'
    });
    let userStr = localStorage.getItem("currentUser");
      let user = "anonymous";
      if (userStr) {
        user = JSON.parse(userStr).fullName ?? "";
      }
    airbrake.notify({
      error: error,
      info: errorInfo,
      params: {user: user, location: "errorHandling"}
    });

  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <React.Fragment>
          <Alert severity="error">
            <h2>Παρουσιάστηκε σφάλμα</h2>
            <Typography gutterBottom>
              Προσπαθήστε να κάνετε ξανά <a href="/logout">login</a>
            </Typography>
            <Typography gutterBottom>
              Αν το πρόβλημα συνεχίζεται, καλέστε την τεχνική υποστήριξη στο{" "}
              <a className="phone-number" href="tel:00302100108950">
                210 010 8950
              </a>
            </Typography>
          </Alert>
        </React.Fragment>
      );
    } else {
      return this.props.children;
    }
  }
}
