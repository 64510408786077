import React from 'react';
import withStyles from "@material-ui/styles/withStyles";
import { withRouter } from "react-router-dom";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CircularProgress from '@material-ui/core/CircularProgress';

import { storeService } from '_services/store.service';

const styles = ({
  actions: {
    display: "block"
  },
  error: {
    "margin-top": "10px"
  }
})

export class ChooseStore extends React.Component {

  redirectToOrder = () => {
    const { history } = this.props;
    if(history) history.push('/order');
  };

  constructor(props) {
    super(props);
    let open = true;
    if (props.open !== undefined)
      open = props.open;

    this.state = { stores: [], loading: true, selectedStore : 0, errorMsg : "", open : open, error : undefined };
    this.storeSelect = this.storeSelect.bind(this);
    this.saveStore = this.saveStore.bind(this);
  }

  componentDidMount() {
    this.populateStoreData();
  }

  static getDerivedStateFromProps(props, state) {
    return { open: props.open };
  }

  storeSelect(store) {
    if (this.state.selectedStore !== store.storeId)
      this.setState({selectedStore : store.storeId, selectedStoreName : store.friendlyName});
    else
      this.setState({selectedStore : 0, selectedStoreName : ""});
  }

  saveStore()
  {
    if (this.state.selectedStore !== 0)
    {
      storeService.storeUserStore(this.state.selectedStore, this.state.selectedStoreName)
      .then(status => {
        if (status === true) {
          if (this.props.closeDialog !== undefined)
            this.props.closeDialog();
          else
            this.redirectToOrder();
        }
        else
          this.setState( { errorMsg : status} );
      });
    }
  }

  renderStoreTable(stores) {
    return (
        <List>
            {stores.map(store =>
                <ListItem className="pharmacy-item" button key={store.storeId} onClick={() => this.storeSelect(store)} 
                  {... ((store.storeId === this.state.selectedStore) ? {selected : true} : undefined) }>
                  <div className="data-row"><span className="data-label">ΕΠΩΝΥΜΙΑ: </span><span className="data-value">{store.friendlyName}</span></div>
                  <div className="data-row"><span className="data-label">ΔΙΕΥΘΥΝΣΗ:</span><span className="data-value">{store.addressLine1 + " " + store.city}</span></div>
              </ListItem>
              )
            }
        </List>
    );
  }

  render() {
    if (this.state.error)
      throw new Error(this.state.error);

    const { classes } = this.props;
    let contents = this.state.loading
      ? <div className="progress-container"><CircularProgress /></div>
      : this.renderStoreTable(this.state.stores);

    return (
        <Dialog open={this.state.open} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Επιλογή φαρμακείου</DialogTitle>
          <DialogContent>
            {contents}
          </DialogContent>
          <DialogActions className={classes.actions}>
                  <Button fullWidth variant="contained" color="secondary" node="button" onClick={this.saveStore} disabled={this.state.selectedStore === 0}>ΑΠΟΘΗΚΕΥΣΗ</Button>
              {this.state.errorMsg && <div className={classes.error}>{this.state.errorMsg}</div>}
          </DialogActions>
        </Dialog>
    );
  }

  async populateStoreData() {
    try {
      const response = await storeService.getUserStores();
      const data = await response;
      this.setState({ stores: data, loading: false });
    } catch (error) {
      this.setState( { error: error});
    }
  }
}

export default withRouter(withStyles(styles)(ChooseStore));