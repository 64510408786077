import { handleResponse } from '_helpers/handle-response';

export const brandTextsService = {
    getBrandText
};

async function getBrandText(key){
    const requestOptions = {
        method: 'GET',
    };

    return await fetch(`${process.env.REACT_APP_MERCURYURL}api/brands/GetBrandText?brand=1&lang=2&key=${key}`, requestOptions)
        .then(handleResponse)
        .then(brandText => {
            return brandText;
    });
}
